import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { auth } from './firebase.service'

interface UserType {
    email: string | null
    uid: string | null
}

const AuthContext = createContext({})

export const useAuth = () => useContext<any>(AuthContext)

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<UserType>({ email: 'dummy', uid: 'dummy' })
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    localStorage.setItem('token', token)
                })
                setUser({
                    email: user.email,
                    uid: user.uid,
                })
            } else {
                setUser({ email: null, uid: null })
                localStorage.setItem('token', '')
            }
        })
        setLoading(false)

        return () => unsubscribe()
    }, [])

    const logIn = (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logOut = async () => {
        setUser({ email: null, uid: null })
        localStorage.setItem('token', '')
        await signOut(auth)
    }

    return <AuthContext.Provider value={{ user, logIn, logOut }}>{loading ? null : children}</AuthContext.Provider>
}

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    let navigate = useNavigate()
    const { user } = useAuth()

    useEffect(() => {
        if (!user.uid) {
            navigate('/admin/login')
        }
    }, [navigate, user])
    return <div>{user ? children : null}</div>
}
