const fecskeBg = '/fecske_bg.webp'
const kisfecskeBg = '/kisfecske_bg.webp'

export const getRestaurantForService = () => {
    const path = window.location.pathname
    if (path.includes('kisfecske')) return 'kisfecske'
    else if (path.includes('fecske')) return 'fecske'
    else return 'none'
}

export const getRestaurant = (path: string) => {
    if (path.includes('kisfecske')) return 'kisfecske'
    else if (path.includes('fecske')) return 'fecske'
    else return 'none'
}

export const getRestaurantBackground = (path: string) => {
    if (path.includes('kisfecske')) return kisfecskeBg
    if (path.includes('fecske')) return fecskeBg
    else return fecskeBg
}
