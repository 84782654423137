import { Badge, Container, HStack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { DeleteDialog } from 'admin/components/customModal/deleteDialog'
import { CustomModalEdit } from 'admin/components/customModal/edit'
import { CustomModalNew } from 'admin/components/customModal/new'
import { AxiosResponse } from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import { TdStyle } from 'style'
import { eventsProps } from 'types'

import { backendUrl } from '../../../../utils'
import EventsForm from './eventsForm'

interface Props {
    title: string
    getAllFunc: (setEvents: React.Dispatch<React.SetStateAction<eventsProps[] | undefined>>) => Promise<void>
    postFunc: (
        name: string,
        englishName: string,
        abstract: string,
        englishAbstract: string,
        description: string,
        englishDescription: string,
        place: string,
        date: string,
        imageFile: any
    ) => Promise<void>
    updateFunc: () => void
    deleteFunc: (id: string, image?: string) => Promise<AxiosResponse<any, any>>
}

const EventsTable = ({ getAllFunc, postFunc, updateFunc, deleteFunc, title }: Props) => {
    const [events, setEvents] = useState<eventsProps[]>()

    const [refresh, forceUpdate] = useReducer((x) => x + 1, 0)
    const [data] = useState<eventsProps>()

    const headers = ['Név', 'Angol név', 'Rövid leírás', 'Helyszín', 'Dátum', 'Kép']
    const { isOpen, onClose } = useDisclosure()

    const postHandler = async (
        name: string,
        englishName: string,
        abstract: string,
        englishAbstract: string,
        description: string,
        englishDescription: string,
        place: string,
        date: string,
        imageFile: any
    ) => {
        try {
            await postFunc(name, englishName, abstract, englishAbstract, description, englishDescription, place, date, imageFile)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const updateHandler = async () => {
        try {
            await updateFunc()
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const deleteHandler = async (delete_id: string, image?: string) => {
        try {
            await deleteFunc(delete_id, image)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getAllFunc(setEvents)
    }, [getAllFunc, refresh])

    return (
        <>
            <Container maxW="100%" bg="white" rounded={'md'}>
                <HStack paddingTop={6} paddingBottom={6} justifyContent={'space-between'}>
                    <Text fontSize="xl" fontWeight={'bold'}>
                        {title}
                    </Text>
                    <CustomModalNew modalName={`${title} hozzáadása`}>
                        <EventsForm postForm={postHandler}></EventsForm>
                    </CustomModalNew>
                    <CustomModalEdit modalName={`${title} szerkesztése`} onClose={onClose} isOpen={isOpen}>
                        <EventsForm data={data} updateForm={updateHandler}></EventsForm>
                    </CustomModalEdit>
                </HStack>
                <TableContainer>
                    <Table variant="striped" colorScheme="teal">
                        <Thead>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                                <Th>{<Badge colorScheme="red">Törlés</Badge>}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {events &&
                                events.map((item, index) => {
                                    return (
                                        <Tr key={index}>
                                            <Td style={TdStyle}>{item.name}</Td>
                                            <Td style={TdStyle}>{item.english.name}</Td>
                                            <Td style={TdStyle}>{item.abstract}</Td>
                                            <Td style={TdStyle}>{item.place}</Td>
                                            <Td style={TdStyle}>{item.date}</Td>
                                            <Td style={TdStyle}>
                                                <img src={`${backendUrl}${item.image}`} alt="" style={{ maxWidth: '3rem' }} />
                                            </Td>

                                            <Td>
                                                <DeleteDialog
                                                    title={'Kategória törlése'}
                                                    question={`Biztosan törölni szeretnéd a(z) ${item.name} elemet?`}
                                                    deleteItem={deleteHandler}
                                                    delete_id={item._id}
                                                    image={item.image}
                                                ></DeleteDialog>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default EventsTable
