import * as foodCategoryService from 'services/foodCategory.service'
import { categoryProps } from 'types'

export const getAllFoodCategories = async (
    setFoodCategory: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>
): Promise<void> => {
    try {
        const res = await foodCategoryService.getAll()
        setFoodCategory(res.data)
    } catch (error) {
        throw error
    }
}

export const updateFoodCategory = async (id: string, index: string, name: string, englishName: string): Promise<any> => {
    try {
        const res = await foodCategoryService.update({
            _id: id,
            index: index,
            name: name,
            english: { name: englishName },
        })

        return res
    } catch (error) {
        throw error
    }
}

export const postFoodCategory = async (name: string, englishName: string): Promise<any> => {
    try {
        const res = await foodCategoryService.post({
            index: 1,
            name: name,
            english: { name: englishName },
        })

        return res
    } catch (error) {
        throw error
    }
}

export const deleteFoodCategory = async (id: string): Promise<any> => {
    try {
        const res = await foodCategoryService.deleteItem({ id })

        return res
    } catch (error) {
        throw error
    }
}
