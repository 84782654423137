import SidebarWithHeader from 'admin/components/sidebar'
import DrinksTable from 'admin/components/tables/drinks/drinksTable'
import { deleteDrinksItem, getAllDrinks, postDrinksForm, updateDrinksForm } from 'admin/components/tables/drinks/functions'
import React from 'react'

const AdminDrinksPage = () => {
    return (
        <SidebarWithHeader>
            <DrinksTable
                getAllFunc={getAllDrinks}
                postFunc={postDrinksForm}
                updateFunc={updateDrinksForm}
                deleteFunc={deleteDrinksItem}
                title={'Itallap'}
            ></DrinksTable>
        </SidebarWithHeader>
    )
}

export default AdminDrinksPage
