import styled from 'styled-components'

export const HeroContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 20rem;
    background: linear-gradient(45deg, rgba(51, 29, 10, 1) 0%, rgba(59, 36, 15, 1) 25%, rgba(79, 51, 27, 1) 65%, rgba(85, 56, 30, 1) 100%);
    justify-content: space-between;
    @media screen and (max-width: 1023px) {
        height: 13.75rem;
    }
`

export const HeroDesign = styled.img`
    position: relative;
    height: calc(100% - 64px);
    top: 64px;
    left: 0;

    @media screen and (max-width: 1023px) {
        display: none;
    }
`

export const HeroText = styled.p`
    font-size: 4.375rem;
    font-weight: 300;
    font-family: Josefin Sans;
    color: #fff;
    position: relative;
    align-self: flex-end;
    margin-right: 11.75%;
    text-shadow: 2px 2px #000;

    @media (min-width: 1024px) and (max-width: 1200px) {
        right: 1.5625rem;
    }

    @media screen and (max-width: 1023px) {
        display: flex;
        margin: auto;
        margin-top: 6rem;
        width: 100%;
        position: relative;
        align-items: center;
        justify-content: center;
        bottom: unset;
        right: unset;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
    }

    @media (min-width: 481px) and (max-width: 767px) {
    }

    @media (max-width: 480px) {
        font-size: 3.75rem;
    }
`
