import { HStack, Icon, Link } from '@chakra-ui/react'
import React from 'react'
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'

import { FooterP, FooterPHover } from './Footer.style'

const FooterData = ({ data }: any) => {
    return (
        <>
            <HStack>
                <Icon as={FaMapMarkerAlt} color="#fff" w={7} h={7} marginTop={-1.5} marginLeft={-1}></Icon>
                <Link href={data.maps}>
                    <FooterPHover>{data.address}</FooterPHover>
                </Link>
            </HStack>
            <HStack>
                <Icon as={FaPhoneAlt} color="#fff" w={5} h={5} marginTop={-1.5} marginLeft={0.5}></Icon>
                <FooterP>{data.phone}</FooterP>
            </HStack>
            <HStack>
                <Icon as={IoIosMail} color="#fff" w={8} h={8} marginTop={-1.5}></Icon>
                <FooterP>{data.email}</FooterP>
            </HStack>
        </>
    )
}

export default FooterData
