import { Link } from '@chakra-ui/react'
import React from 'react'

import BaseSVG from './kisfecskeHover/BaseSVG'
import ContactSVG from './kisfecskeHover/ContactSVG'
import DrinksSVG from './kisfecskeHover/DrinksSVG'
import MenuSVG from './kisfecskeHover/MenuSVG'
import TextSVG from './kisfecskeHover/TextSVG'
import WeeklySVG from './kisfecskeHover/WeeklySVG'

function KisfecskeHover() {
    return (
        <>
            <svg id="kisfecskerajz" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1240 960" style={{ maxWidth: '100%' }}>
                <defs>
                    <style>{'.cls-1{fill:#fff}.cls-2{fill:#ffad1d}.cls-3{fill:#f0ddbd}'}</style>
                </defs>
                <BaseSVG></BaseSVG>
                <Link href="/kisfecske/etlap">
                    <MenuSVG></MenuSVG>
                </Link>
                <Link href="/kisfecske/itallap">
                    <DrinksSVG></DrinksSVG>
                </Link>
                <Link href="/kisfecske/hetimenu">
                    <WeeklySVG></WeeklySVG>
                </Link>
                <Link href="/kapcsolat">
                    <ContactSVG></ContactSVG>
                </Link>
                <path id="blackbox" d="M0 0h1239.68v133.55H0z" />
                <TextSVG></TextSVG>
            </svg>
        </>
    )
}

export default KisfecskeHover
