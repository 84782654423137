import * as allergenService from 'services/allergen.service'
import * as menuService from 'services/menu.service'
import * as weeklyService from 'services/weekly.service'
import { allergensProps, categoryProps, foodProps } from 'types'

export const processAllergens = (allergensData: allergensProps[], allergens: string) => {
    allergensData.forEach((allergen) => {
        allergens = allergens.concat(allergen.short, ', ')
    })
    return allergens
}

export const getAllWeekly = async (
    setFood: React.Dispatch<React.SetStateAction<foodProps[] | undefined>>,
    setCategoriesList: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>,
    setAllergensList: React.Dispatch<React.SetStateAction<allergensProps[] | undefined>>
) => {
    try {
        const foodRes = await weeklyService.getAll()
        setFood(foodRes.data)

        const categoriesRes = await weeklyService.getAllCategories()
        setCategoriesList(categoriesRes.data)

        const allergensRes = await allergenService.getAll()
        setAllergensList(allergensRes.data)
    } catch (error) {
        throw error
    }
}

export const updateWeeklyForm = async (
    id: string,
    index: string,
    name: string,
    englishName: string,
    price: string,
    category: string,
    allergens: string[]
) => {
    try {
        const res = await weeklyService.update({
            _id: id,
            index: index,
            name: name,
            english: { name: englishName },
            price: price,
            category: category,
            allergens: allergens,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const postWeeklyForm = async (name: string, englishName: string, price: string, category: string, allergens: string[]) => {
    try {
        const res = await weeklyService.post({
            index: 1,
            name: name,
            english: { name: englishName },
            price: price,
            category: category,
            allergens: allergens,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const deleteWeeklyItem = async (id: string) => {
    try {
        const res = await weeklyService.deleteItem({ id })
        return res
    } catch (error) {
        throw error
    }
}

export const getAllMenu = async (
    setFood: React.Dispatch<React.SetStateAction<foodProps[] | undefined>>,
    setCategoriesList: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>,
    setAllergensList: React.Dispatch<React.SetStateAction<allergensProps[] | undefined>>
) => {
    try {
        const foodRes = await menuService.getAll()
        setFood(foodRes.data)

        const categoriesRes = await menuService.getAllCategories()
        setCategoriesList(categoriesRes.data)

        const allergensRes = await allergenService.getAll()
        setAllergensList(allergensRes.data)
    } catch (error) {
        throw error
    }
}

export const updateMenuForm = async (
    id: string,
    index: string,
    name: string,
    englishName: string,
    price: string,
    category: string,
    allergens: string[]
) => {
    try {
        const res = await menuService.update({
            _id: id,
            index: index,
            name: name,
            english: { name: englishName },
            price: price,
            category: category,
            allergens: allergens,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const postMenuForm = async (name: string, englishName: string, price: string, category: string, allergens: string[]) => {
    try {
        const res = await menuService.post({
            index: 1,
            name: name,
            english: { name: englishName },
            price: price,
            category: category,
            allergens: allergens,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const deleteMenuItem = async (id: string) => {
    try {
        const res = await menuService.deleteItem({ id })
        return res
    } catch (error) {
        throw error
    }
}
