import styled from 'styled-components'

export const AllergenesContainer = styled.div`
    width: 70%;
    margin: auto;
    margin-top: 3.125rem;
    @media (max-width: 767px) {
        width: 100%;
    }
`

export const AllergenesContent = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
`
export const Text = styled.div`
    font-size: 1.25rem;
    font-weight: 300;
    font-family: Josefin Sans;
    color: #fff;
    @media (max-width: 767px) {
        font-size: 1rem;
    }
`
