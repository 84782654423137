import React from 'react'
import Footer from 'site/components/Footer'
import { Navbar } from 'site/components/Navbar'
import { Container, PlaceHolderNavbar } from 'style'

import { Announcement } from './components/Announcement'
import { News } from './components/News'
import { Restaurants } from './components/Restaurants'

export const LandingPage = () => {
    return (
        <Container>
            <Navbar links={true}></Navbar>
            <PlaceHolderNavbar></PlaceHolderNavbar>
            <Announcement></Announcement>
            <News></News>
            <Restaurants></Restaurants>
            <Footer></Footer>
        </Container>
    )
}
