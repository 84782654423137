import styled from 'styled-components'

export const Image = styled.img`
    max-width: 17.5rem;
    max-height: 17.5rem;
    object-fit: cover;
    border-width: 2rem;
    border-image: url(/fecske_border.png) 34% 33.5% repeat;
    border-style: solid;
`

export const CaptionContainerDesktop = styled.div`
    @media (max-width: 767px) {
        display: none;
    }
`
export const CaptionContainerMobile = styled.div`
    display: none;
    @media (max-width: 767px) {
        display: unset;
    }
`

export const CarouselH1 = styled.h1`
    font-size: 2.5rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
    @media (max-width: 767px) {
        font-size: 2rem;
    }
`

export const CarouselP = styled.p`
    font-size: 1.5625rem;
    font-weight: 300;
    font-family: Josefin Sans;
    color: #fff;
`
