import SidebarWithHeader from 'admin/components/sidebar'
import FoodTable from 'admin/components/tables/food/foodTable'
import { deleteMenuItem, getAllMenu, postMenuForm, updateMenuForm } from 'admin/components/tables/food/functions'
import React from 'react'

const AdminMenuPage = () => {
    return (
        <SidebarWithHeader>
            <FoodTable
                getAllFunc={getAllMenu}
                postFunc={postMenuForm}
                updateFunc={updateMenuForm}
                deleteFunc={deleteMenuItem}
                title={'Étlap'}
            ></FoodTable>
        </SidebarWithHeader>
    )
}

export default AdminMenuPage
