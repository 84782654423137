import { Link, Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { NavbarLinkProps } from 'types'

import { NavLink, NavMenuLink } from './Navbar.style'

interface Props {
    link: NavbarLinkProps
}

const NavbarHover = ({ link }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Menu key={link.name} isOpen={isOpen}>
            <div onMouseEnter={onOpen} onMouseLeave={onClose}>
                <MenuButton as={NavLink} margin={0} padding={0}>
                    {link.name}
                </MenuButton>
                <MenuList background="black">
                    <Link key={`/fecske/${link.name}`} href={`/fecske/${link.path}`} style={{ textDecoration: 'none' }}>
                        <NavMenuLink>Fecske</NavMenuLink>
                    </Link>
                    <Link key={`/kisfecske/${link.name}`} href={`/kisfecske/${link.path}`} style={{ textDecoration: 'none' }}>
                        <NavMenuLink>Kis Fecske</NavMenuLink>
                    </Link>
                </MenuList>
            </div>
        </Menu>
    )
}

export default NavbarHover
