import React from 'react'
import { galleryProps } from 'types'

import { backendUrl } from '../../../utils'
import { CardContainerGallery, GalleryImage } from './Cards.style'

interface Props {
    data: galleryProps[] | undefined
}

const GalleryCards = ({ data }: Props) => {
    return (
        <>
            <CardContainerGallery>
                {data?.map((item, index) => {
                    return <GalleryImage key={index} src={`${backendUrl}${item.image}`} alt="" />
                })}
            </CardContainerGallery>
        </>
    )
}

export default GalleryCards
