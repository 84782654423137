import SidebarWithHeader from 'admin/components/sidebar'
import EventsTable from 'admin/components/tables/events/eventsTable'
import { deleteFunction, getAllFunction, postFunction, updateFunction } from 'admin/components/tables/events/functions'

const AdminEventsPage = () => {
    return (
        <SidebarWithHeader>
            <EventsTable
                getAllFunc={getAllFunction}
                postFunc={postFunction}
                updateFunc={updateFunction}
                deleteFunc={deleteFunction}
                title={'Események'}
            ></EventsTable>
        </SidebarWithHeader>
    )
}

export default AdminEventsPage
