import { getAllGallery } from 'admin/components/tables/gallery/functions'
import React, { useEffect, useState } from 'react'
import GalleryCards from 'site/components/Cards/GalleryCards'
import { BackgroundContainerColored } from 'style'
import { galleryProps } from 'types'

const Gallery = () => {
    const [gallery, setGallery] = useState<galleryProps[]>()

    useEffect(() => {
        getAllGallery(setGallery)
    }, [])

    return (
        <BackgroundContainerColored>
            <GalleryCards data={gallery}></GalleryCards>
        </BackgroundContainerColored>
    )
}

export default Gallery
