import React from 'react'

const TextSVG = () => {
    return (
        <>
            <text
                transform="translate(679.14 122.29)"
                style={{
                    fontSize: 120,
                    fontFamily: 'JosefinSans-Light,Josefin Sans',
                    fontWeight: 300,
                    fill: '#fff',
                }}
                id="text"
            >
                {'Kis '}
                <tspan
                    x={186.6}
                    y={0}
                    style={{
                        letterSpacing: '-.07em',
                    }}
                >
                    {'F\n            '}
                </tspan>
                <tspan x={247.8} y={0}>
                    {'ecs'}
                </tspan>
                <tspan
                    x={402.36}
                    y={0}
                    style={{
                        letterSpacing: '-.02em',
                    }}
                >
                    {'k'}
                </tspan>
                <tspan x={453.96} y={0}>
                    {'e'}
                </tspan>
            </text>
        </>
    )
}

export default TextSVG
