import './styles/globals.css'
import './styles/animation.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { ChakraProvider } from '@chakra-ui/react'
import { startMultiLanguageSupport } from 'i18n'
import ProtectedAdminAnnouncementPage from 'pages/admin/common/announcement'
import ProtectedAdminEventsPage from 'pages/admin/common/events'
import ProtectedAdminGalleryPage from 'pages/admin/common/gallery'
import ProtectedAdminNewsPage from 'pages/admin/common/news'
import ProtectedAdminShopPage from 'pages/admin/common/shop'
import ProtectedAdminDrinksCategoriesPage from 'pages/admin/fecske/categories/drinks'
import ProtectedAdminFoodCategoriesPage from 'pages/admin/fecske/categories/food'
import ProtectedAdminDrinksPage from 'pages/admin/fecske/drinks'
import ProtectedAdminMenuPage from 'pages/admin/fecske/menu'
import ProtectedAdminWeeklyPage from 'pages/admin/fecske/weekly'
import LoginPage from 'pages/admin/login'
import { ContactPage } from 'pages/ContactPage'
import { DrinksPage } from 'pages/DrinksPage'
import { EventsPage } from 'pages/EventsPage'
import { GalleryPage } from 'pages/GalleryPage'
import { LandingPage } from 'pages/LandingPage'
import { MenuPage } from 'pages/MenuPage'
import { WeeklyPage } from 'pages/WeeklyPage'
import { Route, Routes } from 'react-router-dom'
import { AuthContextProvider } from 'services/auth.service'

function App() {
    startMultiLanguageSupport()

    return (
        <AuthContextProvider>
            <ChakraProvider>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/esemenyek" element={<EventsPage />} />
                    <Route path="/galeria" element={<GalleryPage />} />
                    <Route path="/kapcsolat" element={<ContactPage />} />
                    <Route path="/fecske/etlap" element={<MenuPage />} />
                    <Route path="/fecske/itallap" element={<DrinksPage />} />
                    <Route path="/fecske/hetimenu" element={<WeeklyPage />} />
                    <Route path="/kisfecske/etlap" element={<MenuPage />} />
                    <Route path="/kisfecske/itallap" element={<DrinksPage />} />
                    <Route path="/kisfecske/hetimenu" element={<WeeklyPage />} />
                    <Route path="/admin/common/announcement" element={<ProtectedAdminAnnouncementPage />} />
                    <Route path="/admin/common/events" element={<ProtectedAdminEventsPage />} />
                    <Route path="/admin/common/gallery" element={<ProtectedAdminGalleryPage />} />
                    <Route path="/admin/common/news" element={<ProtectedAdminNewsPage />} />
                    <Route path="/admin/common/shop" element={<ProtectedAdminShopPage />} />
                    <Route path="/admin/fecske/categories/drinks" element={<ProtectedAdminDrinksCategoriesPage />} />
                    <Route path="/admin/fecske/categories/food" element={<ProtectedAdminFoodCategoriesPage />} />
                    <Route path="/admin/fecske/drinks" element={<ProtectedAdminDrinksPage />} />
                    <Route path="/admin/fecske/menu" element={<ProtectedAdminMenuPage />} />
                    <Route path="/admin/fecske/weekly" element={<ProtectedAdminWeeklyPage />} />
                    <Route path="/admin/fecske" element={<ProtectedAdminWeeklyPage />} />
                    <Route path="/admin/kisfecske/categories/drinks" element={<ProtectedAdminDrinksCategoriesPage />} />
                    <Route path="/admin/kisfecske/categories/food" element={<ProtectedAdminFoodCategoriesPage />} />
                    <Route path="/admin/kisfecske/drinks" element={<ProtectedAdminDrinksPage />} />
                    <Route path="/admin/kisfecske/menu" element={<ProtectedAdminMenuPage />} />
                    <Route path="/admin/kisfecske/weekly" element={<ProtectedAdminWeeklyPage />} />
                    <Route path="/admin/kisfecske" element={<ProtectedAdminWeeklyPage />} />
                    <Route path="/admin/login" element={<LoginPage />} />
                </Routes>
            </ChakraProvider>
        </AuthContextProvider>
    )
}

export default App
