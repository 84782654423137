export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const createFormData = (data: any) => {
    const formData = new FormData()

    for (const key in data) {
        formData.append(key, data[key])
    }

    return formData
}

//export const backendUrlBase = 'http://localhost:5000'
//export const backendUrl = `${backendUrlBase}/public`
export const backendUrlBase = 'https://fecskepresszo.hu/api'
export const backendUrl = `${backendUrlBase}/public`
