import { VStack } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontalLineMenu, PlaceHolder } from 'style'
import { categoryProps, drinksProps } from 'types'

import {
    CardContainer,
    CardFull,
    CardItemDrinks,
    CardItemText,
    CardItemTextDrinksLight,
    CardItemTextDrinksP,
    CardTitle,
    CardWrapper,
    Texture,
    TextWrapper,
} from './Cards.style'

interface Props {
    data: drinksProps[] | undefined
    categories: categoryProps[] | undefined
}
const paperTexture = '/black-linen.png'

const MenuCardsDrinks = ({ data, categories }: Props) => {
    const { i18n } = useTranslation()

    return (
        <CardContainer>
            <PlaceHolder />
            {categories?.map((category, index) => {
                return (
                    <>
                        <CardFull key={index} margin="7% 5% 0% 5%">
                            <Texture src={paperTexture}>
                                <CardWrapper>
                                    <CardTitle> {i18n.language === 'en' ? category?.english?.name : category.name}</CardTitle>
                                    {data?.map((current, index) => {
                                        if (current?.category?.name === category?.name)
                                            return (
                                                <>
                                                    <CardItemDrinks key={index}>
                                                        <TextWrapper>
                                                            <VStack>
                                                                <CardItemText>
                                                                    {i18n.language === 'en' ? current.english?.name : current.name}
                                                                </CardItemText>
                                                                {current.description && (
                                                                    <CardItemTextDrinksLight>
                                                                        {i18n.language === 'en'
                                                                            ? `(${current.english.description})`
                                                                            : `(${current.description})`}
                                                                    </CardItemTextDrinksLight>
                                                                )}
                                                            </VStack>
                                                        </TextWrapper>
                                                        <TextWrapper>
                                                            {current.priceArray.map((item) => {
                                                                return (
                                                                    <VStack key={item.id} alignItems={'flex-start'}>
                                                                        <CardItemTextDrinksP>{`${item.price}.-`}</CardItemTextDrinksP>
                                                                        <CardItemTextDrinksLight>{`${item.quantity} ${item.unit}`}</CardItemTextDrinksLight>
                                                                    </VStack>
                                                                )
                                                            })}
                                                        </TextWrapper>
                                                    </CardItemDrinks>
                                                    <HorizontalLineMenu></HorizontalLineMenu>
                                                </>
                                            )
                                        return []
                                    })}
                                </CardWrapper>
                            </Texture>
                        </CardFull>
                    </>
                )
            })}
            <PlaceHolder />
        </CardContainer>
    )
}

export default MenuCardsDrinks
