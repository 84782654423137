import {
    Badge,
    Container,
    HStack,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react'
import { DeleteDialog } from 'admin/components/customModal/deleteDialog'
import { CustomModalEdit } from 'admin/components/customModal/edit'
import { CustomModalNew } from 'admin/components/customModal/new'
import React, { useEffect, useReducer, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { TdStyle } from 'style'
import { allergensProps, categoryProps, foodProps } from 'types'

import FoodForm from './foodForm'
import { processAllergens } from './functions'

interface Props {
    title: string
    getAllFunc: (
        setFood: React.Dispatch<React.SetStateAction<foodProps[] | undefined>>,
        setCategoriesList: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>,
        setAllergensList: React.Dispatch<React.SetStateAction<allergensProps[] | undefined>>
    ) => Promise<void>
    postFunc: (name: string, englishName: string, price: string, category: string, allergens: string[]) => void
    updateFunc: (id: string, index: string, name: string, englishName: string, price: string, category: string, allergens: string[]) => void
    deleteFunc: (id: string) => any
}

const FoodTable = ({ getAllFunc, postFunc, updateFunc, deleteFunc, title }: Props) => {
    const [food, setFood] = useState<foodProps[]>()
    const [categoriesList, setCategoriesList] = useState<categoryProps[]>()
    const [allergensList, setAllergensList] = useState<allergensProps[]>()
    const [refresh, forceUpdate] = useReducer((x) => x + 1, 0)
    const [data, setData] = useState<foodProps>()

    const headers = ['Név', 'Angol név', 'Ár', 'Kategóra', 'Allergének']
    const { isOpen, onOpen, onClose } = useDisclosure()

    const postHandler = async (name: string, englishName: string, price: string, category: string, allergens: string[]) => {
        try {
            await postFunc(name, englishName, price, category, allergens)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const updateHandler = async (
        id: string,
        index: string,
        name: string,
        englishName: string,
        price: string,
        category: string,
        allergens: string[]
    ) => {
        try {
            await updateFunc(id, index, name, englishName, price, category, allergens)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const deleteHandler = async (delete_id: string) => {
        try {
            await deleteFunc(delete_id)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getAllFunc(setFood, setCategoriesList, setAllergensList)
    }, [getAllFunc, refresh])
    let allergens: string = ''
    return (
        <>
            <Container maxW="100%" bg="white" rounded={'md'}>
                <HStack paddingTop={6} paddingBottom={6} justifyContent={'space-between'}>
                    <Text fontSize="xl" fontWeight={'bold'}>
                        {title}
                    </Text>
                    {categoriesList && allergensList && (
                        <CustomModalNew modalName="Étel hozzáadása">
                            <FoodForm categoriesList={categoriesList} allergensList={allergensList} postForm={postHandler}></FoodForm>
                        </CustomModalNew>
                    )}
                    {categoriesList && allergensList && (
                        <CustomModalEdit modalName="Étel szerkesztése" onClose={onClose} isOpen={isOpen}>
                            <FoodForm
                                categoriesList={categoriesList}
                                allergensList={allergensList}
                                data={data}
                                updateForm={updateHandler}
                            ></FoodForm>
                        </CustomModalEdit>
                    )}
                </HStack>
                <TableContainer>
                    <Table variant="striped" colorScheme="teal">
                        <Thead>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                                <Th>{<Badge colorScheme="blue">Módosítás</Badge>}</Th>
                                <Th>{<Badge colorScheme="red">Törlés</Badge>}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {food &&
                                food.map((item, index) => {
                                    allergens = ''
                                    allergens = processAllergens(item.allergens, allergens)

                                    return (
                                        <Tr key={index}>
                                            <Td style={TdStyle}>{item.name}</Td>
                                            <Td style={TdStyle}>{item.english?.name}</Td>
                                            <Td style={TdStyle}>{item.price}</Td>
                                            <Td style={TdStyle}>{item.category?.name}</Td>
                                            <Td style={TdStyle}>{allergens.slice(0, -2)}</Td>
                                            <Td>
                                                <Icon
                                                    as={FiEdit2}
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-console
                                                        onOpen()
                                                        setData(item)
                                                    }}
                                                ></Icon>
                                            </Td>
                                            <Td>
                                                <DeleteDialog
                                                    title={'Étel törlése'}
                                                    question={`Biztosan törölni szeretnéd a(z) ${item.name} elemet?`}
                                                    deleteItem={deleteHandler}
                                                    delete_id={item._id}
                                                ></DeleteDialog>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default FoodTable
