import { Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../../services/auth.service'
import { AuthCard, AuthCardWrapper, AuthContainer, ButtonWrapper, TextH1 } from './Auth.style'

export default function LoginContent() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [show, setShow] = React.useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const { logIn } = useAuth()
    let navigate = useNavigate()

    const handleShow = () => setShow(!show)

    async function handleSubmit(e: { preventDefault: () => void }, link: string) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await logIn(email, password)
            navigate(`/admin/${link}`)
        } catch {
            setError('Sikertelen bejelentkezés')
        }

        setLoading(false)
    }

    return (
        <>
            <AuthContainer>
                <AuthCard>
                    <AuthCardWrapper>
                        <TextH1 color={'white'}>Bejelentkezés</TextH1>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <FormControl mt={4}>
                            <FormLabel color={'white'}>Email</FormLabel>
                            <Input color={'white'} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel color={'white'}>Jelszó</FormLabel>
                            <InputGroup color={'white'}>
                                <Input
                                    color={'white'}
                                    type={show ? 'text' : 'password'}
                                    placeholder="Jelszó"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <InputRightElement width="4.5rem" marginRight={'0.5rem'}>
                                    <Button background={'grey'} h="1.75rem" size="sm" onClick={handleShow}>
                                        {show ? 'Elrejtés' : 'Mutatás'}
                                    </Button>
                                </InputRightElement>{' '}
                            </InputGroup>
                        </FormControl>
                        <ButtonWrapper>
                            <Button
                                marginTop={{ base: 8, md: 8 }}
                                color={'white'}
                                background={'#cbb87e'}
                                _hover={{ background: '#a7884b' }}
                                disabled={loading}
                                className="w-100"
                                onClick={(e) => {
                                    handleSubmit(e, 'fecske')
                                }}
                            >
                                Bejelentkezés a Fecskébe
                            </Button>
                            <Button
                                marginTop={{ base: 4, md: 8 }}
                                color={'white'}
                                background={'#bba161'}
                                _hover={{ background: '#a7884b' }}
                                disabled={loading}
                                className="w-100"
                                onClick={(e) => {
                                    handleSubmit(e, 'kisfecske')
                                }}
                            >
                                Bejelentkezés a Kis Fecskébe
                            </Button>
                        </ButtonWrapper>
                        <div className="w-100 text-center mt-3 text-white">
                            <Text>© fecskepresszo.hu</Text>
                        </div>
                    </AuthCardWrapper>
                </AuthCard>
            </AuthContainer>
        </>
    )
}
