import styled from 'styled-components'

interface Props {
    fontWeight: string
}

export const CardContainer = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
`

export const CardContainerEvents = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    width: 80%;
    height: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 1.25rem;
    }
`

export const CardContainerGallery = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    max-width: 80%;
    flex-wrap: wrap;
    height: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
        justify-content: center;
    }
`

export const Card = styled.div`
    background: linear-gradient(
        210deg,
        rgba(106, 68, 22, 1) 0%,
        rgba(96, 62, 17, 1) 25%,
        rgba(85, 53, 12, 1) 50%,
        rgba(78, 46, 9, 1) 75%,
        rgba(64, 34, 4, 1) 100%
    );
    width: 40%;
    height: min-content;
    margin: 5% 5% 0% 5%;
    box-shadow: 0 0 3px #000;
    @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
    }
`

export const CardFull = styled.div<{ margin: string }>`
    background: linear-gradient(
        210deg,
        rgba(106, 68, 22, 1) 0%,
        rgba(96, 62, 17, 1) 25%,
        rgba(85, 53, 12, 1) 50%,
        rgba(78, 46, 9, 1) 75%,
        rgba(64, 34, 4, 1) 100%
    );
    width: 100%;
    height: min-content;
    margin: ${({ margin }) => margin};
    box-shadow: 0 0 3px #000;
    @media (max-width: 767px) {
        width: 100%;
        max-width: 90%;
    }
`

export const Texture = styled.div<{ src?: string }>`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        background: url(${({ src }) => src});
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
    }
`

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.875rem;
    padding-top: 1.3rem;

    @media (max-width: 767px) {
        padding: 1rem;
        max-width: 100%;
    }
`
export const CardWrapperEventsHorizontal = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;

    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: unset;
    }
`

export const CardWrapperEventsVertical = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: min-content;
    width: 100%;
    margin-right: 1rem;

    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: unset;
        padding-top: 0rem;
        padding-bottom: 1.5rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
`

export const CardTitle = styled.p`
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: Josefin Sans;
    text-transform: uppercase;
    align-self: center;
    z-index: 10;

    @media (max-width: 767px) {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
`

export const CardTitleEvents = styled.p`
    color: #fff;
    font-size: 1.875rem;
    font-weight: 400;
    font-family: Josefin Sans;
    z-index: 10;
    margin-bottom: 1rem;

    @media (max-width: 767px) {
        font-size: 1.5rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
`

export const CardImage = styled.img`
    max-height: 17.5rem;
    object-fit: cover;
    z-index: 10;
    border: 5px solid #200f01;

    @media (max-width: 767px) {
        max-height: unset;
        max-width: 100%;
        border-left: unset;
    }
`
export const GalleryImage = styled.img`
    max-height: 12rem;
    object-fit: cover;
    z-index: 10;
    border-width: 10px;
    border-style: solid;
    border-image: linear-gradient(120deg, rgba(84, 56, 30, 1), rgba(52, 30, 11, 1)) 1;
    margin: 1rem;
    margin-top: 2rem;

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        top: 9px;
        border-color: transparent transparent transparent #a00;
        border-width: 11px;
    }
`

export const CardItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: unset;
        margin-bottom: 1rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        flex-wrap: wrap;
    }
`
export const CardItemDrinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.25rem;
`

export const CardItemEvents = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.25rem;
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const CardItemText = styled.p`
    color: #fff;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: Josefin Sans;
    z-index: 10;
    align-self: start;
    height: 100%;
    margin-top: 0.5rem;

    @media (max-width: 767px) {
        margin-top: unset;
        font-size: 1rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
`

export const CardItemTextEvents = styled.p<Props>`
    color: #fff;
    font-size: 1.25rem;
    font-weight: ${(props) => props.fontWeight};
    font-family: Josefin Sans;
    z-index: 10;
    align-self: start;
    height: 100%;

    @media (max-width: 767px) {
        font-size: 1.25rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
`
export const CardItemTextDrinksP = styled.p`
    color: #fff;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: Josefin Sans;
    margin-right: 2.5rem;
    z-index: 10;
    @media (max-width: 767px) {
        font-size: 0.7rem;
        margin-right: 1rem;
    }
`

export const CardItemTextDrinksLight = styled.p`
    color: #fff;
    opacity: 0.5;
    font-size: 20px;
    font-weight: 300;
    font-family: Josefin Sans;
    margin-right: 40px;
    z-index: 10;
    @media (max-width: 767px) {
        font-size: 0.7rem;
    }
`
