import * as service from 'services/events.service'
import { eventsProps } from 'types'
import { createFormData } from 'utils'

export const getAllFunction = async (setState: React.Dispatch<React.SetStateAction<eventsProps[] | undefined>>): Promise<void> => {
    try {
        const res = await service.getAll()
        setState(res.data)
    } catch (error) {
        throw error
    }
}

export const updateFunction = async (): Promise<any> => {
    try {
        const res = await service.update({})
        return res
    } catch (error) {
        throw error
    }
}

export const postFunction = async (
    name: string,
    englishName: string,
    abstract: string,
    englishAbstract: string,
    description: string,
    englishDescription: string,
    place: string,
    date: string,
    imageFile: any
): Promise<any> => {
    try {
        const formData = createFormData({
            image: imageFile,
            name,
            abstract,
            description,
            englishName,
            englishAbstract,
            englishDescription,
            place,
            date,
        })

        const res = await service.post(formData)
        return res
    } catch (error) {
        throw error
    }
}

export const deleteFunction = async (id: string, image?: string) => {
    try {
        const res = await service.deleteItem({ id, image })
        return res
    } catch (error) {
        throw error
    }
}
