import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const app = initializeApp({
    apiKey: 'AIzaSyDwmDErAE9Yn_ut0tTChPK6xHDWBVQBdyQ',
    authDomain: 'fecske-4d524.firebaseapp.com',
    projectId: 'fecske-4d524',
    storageBucket: 'fecske-4d524.appspot.com',
    messagingSenderId: '430014922210',
    appId: '1:430014922210:web:c6620ce299750916ea8dbb',
    measurementId: 'G-ZGVNBV20M7',
})

export const auth = getAuth(app)
