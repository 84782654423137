import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getRestaurantBackground } from 'restaurant'
import Allergenes from 'site/components/Allergenes'
import MenuCardsFood from 'site/components/Cards/MenuCardsFood'
import { Frame } from 'site/components/Frame'
import { BackgroundContainer } from 'style'
import { categoryProps, foodProps } from 'types'

import { getAllWeekly } from './functions'

const Menu = () => {
    const { pathname } = useLocation()
    const [food, setFood] = useState<foodProps[]>()
    const [categoriesList, setCategoriesList] = useState<categoryProps[]>()

    useEffect(() => {
        getAllWeekly(setFood, setCategoriesList)
    }, [])

    return (
        <BackgroundContainer src={getRestaurantBackground(pathname)}>
            <Frame>
                <MenuCardsFood data={food} categories={categoriesList}></MenuCardsFood>
            </Frame>
            <Allergenes></Allergenes>
        </BackgroundContainer>
    )
}

export default Menu
