import React from 'react'
import { useTranslation } from 'react-i18next'

import { FooterOpeningsContainer, FooterOpeningsH1, FooterP } from './Footer.style'

const FooterOpenings = ({ data }: any) => {
    const { t } = useTranslation()

    return (
        <FooterOpeningsContainer>
            <FooterOpeningsH1>{`${t('openingHours')}:`}</FooterOpeningsH1>
            <FooterP>{data.monday}</FooterP>
            <FooterP>{data.tuesday}</FooterP>
            <FooterP>{data.wednesday}</FooterP>
            <FooterP>{data.thursday}</FooterP>
            <FooterP>{data.friday}</FooterP>
            <FooterP>{data.saturday}</FooterP>
            <FooterP>{data.sunday}</FooterP>
        </FooterOpeningsContainer>
    )
}

export default FooterOpenings
