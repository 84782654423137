import { Button, FormControl, FormLabel, HStack, Input, Select } from '@chakra-ui/react'
import { PriceArrayProps } from 'types'

interface Props {
    priceArray: PriceArrayProps[]
    setPriceArray: React.Dispatch<React.SetStateAction<PriceArrayProps[]>>
}

const UnitControl = ({ priceArray, setPriceArray }: Props) => {
    const unitList = ['', 'cl', 'dl', 'l', 'db']
    const quantityList = ['', '0.1', '0.2', '0.25', '0.3', '0.33', '0.4', '0.5', '0.75', '0.8', '1', '1.5', '2', '4', '8']

    const addInput = () => {
        setPriceArray((previous) => [
            ...previous,
            {
                id: previous.length ? previous[previous.length - 1].id + 1 : 1,
                quantity: '',
                unit: '',
                price: '',
            },
        ])
    }

    const removeInput = () => {
        if (priceArray.length > 1) {
            setPriceArray((previous) => previous.slice(0, -1))
        }
    }

    const handleChange = (value: string, id: number, prop: string) => {
        setPriceArray((previous) =>
            previous.map((item, index) => {
                if (index === id) {
                    return {
                        ...item,
                        [prop]: value,
                    }
                }
                return item
            })
        )
    }

    return (
        <>
            <Button mt={8} type="button" onClick={addInput}>
                Új ár hozzáadása
            </Button>
            <Button mt={8} ml={4} type="button" onClick={removeInput}>
                Ár eltávolítása
            </Button>
            {priceArray.map((item, i) => (
                <HStack mt={8} key={item.id}>
                    <FormControl width={'32'}>
                        <FormLabel>Mennyiség</FormLabel>
                        <Select
                            value={item.quantity}
                            placeholder="Válassz"
                            onChange={(e) => handleChange(e.target.value, item.id, 'quantity')}
                        >
                            {quantityList.map((quantity, index) => (
                                <option key={index} value={quantity}>
                                    {quantity}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl width={'32'}>
                        <FormLabel>Mértékegység</FormLabel>
                        <Select value={item.unit} placeholder="Válassz" onChange={(e) => handleChange(e.target.value, item.id, 'unit')}>
                            {unitList.map((unit, index) => (
                                <option key={index} value={unit}>
                                    {unit}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl width={'32'}>
                        <FormLabel>Ár</FormLabel>
                        <Input value={item.price} placeholder="Ár" onChange={(e) => handleChange(e.target.value, item.id, 'price')} />
                    </FormControl>
                </HStack>
            ))}
        </>
    )
}

export default UnitControl
