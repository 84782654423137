import styled from 'styled-components'

export const FooterContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background: #200f01;
`

export const FooterWrapper = styled.div`
    width: 90%;
    height: 100%;
    margin: auto;
`

export const FooterH1 = styled.p`
    font-size: 1.375rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
`
export const FooterOpeningsContainer = styled.div`
    height: auto;
    width: auto;
    margin-top: 2.5rem !important;
    @media (max-width: 767px) {
        margin-top: 1rem !important;
    }
`

export const FooterOpeningsH1 = styled.p`
    font-size: 1.125rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
`

export const FooterP = styled.p`
    font-size: 1.125rem;
    font-weight: 300;
    font-family: Josefin Sans;
    color: #fff;
`

export const FooterPHover = styled.p`
    font-size: 1.125rem;
    font-weight: 300;
    font-family: Josefin Sans;
    color: #fff;

    &:hover {
        color: #e7d9cd;
    }
`
