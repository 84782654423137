import { FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Select } from '@chakra-ui/react'
import SubmitButtons from 'admin/components/customModal/submitButtons'
import React, { useState } from 'react'
import { eventsProps } from 'types'

interface Props {
    onClose?: () => void
    modalName?: string
    updateForm?: () => void
    postForm?: (
        name: string,
        englishName: string,
        abstract: string,
        englishAbstract: string,
        description: string,
        englishDescription: string,
        place: string,
        date: string,
        imageFile: any
    ) => Promise<void>
    data?: eventsProps
}

const EventsForm = ({ onClose, modalName, data, updateForm, postForm }: Props) => {
    const [name, setName] = useState(data?.name || '')
    const [abstract, setAbstract] = useState(data?.abstract || '')
    const [description, setDescription] = useState(data?.description || '')
    const [englishName, setEnglishName] = useState(data?.english.name || '')
    const [englishAbstract, setEnglishAbstract] = useState(data?.english.abstract || '')
    const [englishDescription, setEnglishDescription] = useState(data?.english.description || '')
    const [place, setPlace] = useState(data?.place || '')
    const [date, setDate] = useState(data?.date || '')
    const [imageFile, setImageFile] = useState<any>()

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault()
        if (data) updateForm && updateForm()
        else postForm && postForm(name, englishName, abstract, englishAbstract, description, englishDescription, place, date, imageFile)
        onClose && onClose()
        setDescription('')
        setEnglishDescription('')
    }

    const placeList = ['Fecske Presszó', 'Kis Fecske Presszó']

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ModalHeader>{modalName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl mt={4}>
                        <FormLabel>Név</FormLabel>
                        <Input placeholder="Név" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol név</FormLabel>
                        <Input placeholder="Angol név" defaultValue={englishName} onChange={(e) => setEnglishName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Rövid leírás</FormLabel>
                        <Input placeholder="Rövid leírás" defaultValue={abstract} onChange={(e) => setAbstract(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol rövid leírás</FormLabel>
                        <Input
                            placeholder="Angol rövid leírás"
                            defaultValue={englishAbstract}
                            onChange={(e) => setEnglishAbstract(e.target.value)}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Helyszín</FormLabel>
                        <Select value={place} placeholder="Válassz kategóriát" onChange={(e) => setPlace(e.target.value)}>
                            {placeList?.map((item, index) => {
                                return (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {/*} <FormControl mt={4}>
                        <FormLabel>Hosszú leírás</FormLabel>
                        <Input placeholder="Hosszú leírás" defaultValue={description} onChange={(e) => setDescription(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol hosszú leírás</FormLabel>
                        <Input
                            placeholder="Angol hosszú leírás"
                            defaultValue={englishDescription}
                            onChange={(e) => setEnglishDescription(e.target.value)}
                        />
                    </FormControl>*/}
                    <FormControl mt={4}>
                        <FormLabel>Dátum</FormLabel>
                        <Input placeholder="Dátum" defaultValue={date} onChange={(e) => setDate(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Kép feltöltés</FormLabel>
                        <input
                            type="file"
                            name="image"
                            onChange={(e) => {
                                setImageFile(e.target.files![0])
                            }}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <SubmitButtons onClose={onClose}></SubmitButtons>
                </ModalFooter>
            </form>
        </>
    )
}

export default EventsForm
