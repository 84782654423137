import AdminFoodCategoriesPage from 'admin/components/PageTemplates/AdminCategories/AdminFoodCategoriesPage'
import React from 'react'

import { ProtectedRoute } from '../../../../../services/auth.service'

const ProtectedAdminFoodCategoriesPage = () => {
    return (
        <ProtectedRoute>
            <AdminFoodCategoriesPage></AdminFoodCategoriesPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminFoodCategoriesPage
