export const fecskeLogo = '/fecske_logo_full.png'

export const fecskeAdat = {
    address: 'Budapest VIII. ker. Baross utca 10.',
    phone: '+36 1 293-1980',
    email: 'fecskepresszo2007@gmail.com',
    maps: 'https://goo.gl/maps/vdc3XKarF3Jyo1FW9',
}
export const kisfecskeAdat = {
    address: 'Budapest VIII. ker. Mikszáth Kálmán tér 2.',
    phone: '+36 1 793-8283',
    email: 'kisfecskepresszo@gmail.com',
    maps: 'https://goo.gl/maps/a3upZrhj6eEZXpfT7',
}

export const fecskeNyitvatartas = {
    monday: `Hétfő: 12:00 - 23:00`,
    tuesday: `Kedd: 12:00 - 23:00`,
    wednesday: `Szerda: 12:00 - 23:00`,
    thursday: `Csütörtök: 12:00 - 23:00`,
    friday: `Péntek: 12:00 - 23:00`,
    saturday: `Szombat: 12:00 - 23:00`,
    sunday: `Vasárnap: 12:00 - 23:00`,
}

export const kisFecskeNyitvatartas = {
    monday: `Hétfő: 10:00 - 23:00`,
    tuesday: `Kedd: 10:00 - 23:00`,
    wednesday: `Szerda: 10:00 - 23:00`,
    thursday: `Csütörtök: 10:00 - 23:00`,
    friday: `Péntek: 10:00 - 23:00`,
    saturday: `Szombat: 12:00 - 23:00`,
    sunday: `Vasárnap: 12:00 - 23:00`,
}

export const fecskeOpenings = {
    monday: `Monday: 12:00 - 23:00`,
    tuesday: `Tuesday: 12:00 - 23:00`,
    wednesday: `Wednesday: 12:00 - 23:00`,
    thursday: `Thursday: 12:00 - 23:00`,
    friday: `Friday: 12:00 - 23:00`,
    saturday: `Saturday: 12:00 - 23:00`,
    sunday: `Sunday: 12:00 - 23:00`,
}

export const kisFecskeOpenings = {
    monday: `Monday: 10:00 - 23:00`,
    tuesday: `Tuesday: 10:00 - 23:00`,
    wednesday: `Wednesday: 10:00 - 23:00`,
    thursday: `Thursday: 10:00 - 23:00`,
    friday: `Friday: 10:00 - 23:00`,
    saturday: `Saturday: 12:00 - 23:00`,
    sunday: `Sunday: 12:00 - 23:00`,
}
