import SidebarWithHeader from 'admin/components/sidebar'
import { deleteFunction, getAllFunction, postFunction, updateFunction } from 'admin/components/tables/news/functions'
import NewsTable from 'admin/components/tables/news/newsTable'
import React from 'react'

const AdminNewsPage = () => {
    return (
        <SidebarWithHeader>
            <NewsTable
                getAllFunc={getAllFunction}
                postFunc={postFunction}
                updateFunc={updateFunction}
                deleteFunc={deleteFunction}
                title={'Hirdetmények'}
            ></NewsTable>
        </SidebarWithHeader>
    )
}

export default AdminNewsPage
