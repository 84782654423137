import AdminShopPage from 'admin/components/PageTemplates/AdminShopPage'
import React from 'react'

import { ProtectedRoute } from '../../../../services/auth.service'

const ProtectedAdminShopPage = () => {
    return (
        <ProtectedRoute>
            <AdminShopPage></AdminShopPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminShopPage
