import AdminDrinksCategoriesPage from 'admin/components/PageTemplates/AdminCategories/AdminDrinksCategoriesPage'
import React from 'react'

import { ProtectedRoute } from '../../../../../services/auth.service'

const ProtectedAdminDrinksCategoriesPage = () => {
    return (
        <ProtectedRoute>
            <AdminDrinksCategoriesPage></AdminDrinksCategoriesPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminDrinksCategoriesPage
