import { FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '@chakra-ui/react'
import SubmitButtons from 'admin/components/customModal/submitButtons'
import React, { useState } from 'react'
import { shopProps } from 'types'

interface Props {
    onClose?: () => void
    modalName?: string
    forceUpdate: React.DispatchWithoutAction
    updateForm?: () => void
    postForm?: (name: string, englishName: string, price: string, salePrice: string, onSale: string, imageFile: any) => Promise<void>
    data?: shopProps
}

const EventsForm = ({ onClose, modalName, data, forceUpdate, updateForm, postForm }: Props) => {
    const [name, setName] = useState(data?.name || '')
    const [englishName, setEnglishName] = useState(data?.english?.name || '')
    const [price, setPrice] = useState(data?.price || '')
    const [salePrice, setSalePrice] = useState(data?.salePrice || '')
    const [onSale, setOnSale] = useState(data?.onSale || '')
    const [imageFile, setImageFile] = useState<any>()

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault()
        if (data) updateForm && updateForm()
        else postForm && postForm(name, englishName, price, salePrice, onSale, imageFile)
        forceUpdate()
        onClose && onClose()
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ModalHeader>{modalName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl mt={4}>
                        <FormLabel>Név</FormLabel>
                        <Input placeholder="Név" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol név</FormLabel>
                        <Input placeholder="Angol név" defaultValue={englishName} onChange={(e) => setEnglishName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Ár</FormLabel>
                        <Input placeholder="Ár" defaultValue={price} onChange={(e) => setPrice(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Kezdvezményes ár</FormLabel>
                        <Input placeholder="Kezdvezményes ár" defaultValue={salePrice} onChange={(e) => setSalePrice(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Akciós</FormLabel>
                        <Input placeholder="Akciós" defaultValue={onSale} onChange={(e) => setOnSale(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Kép feltöltés</FormLabel>
                        <input
                            type="file"
                            name="image"
                            onChange={(e) => {
                                setImageFile(e.target.files![0])
                            }}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <SubmitButtons onClose={onClose}></SubmitButtons>
                </ModalFooter>
            </form>
        </>
    )
}

export default EventsForm
