import * as drinksService from 'services/drinks.service'
import { categoryProps, drinksProps } from 'types'

export const getAllDrinks = async (
    setDrinks: React.Dispatch<React.SetStateAction<drinksProps[] | undefined>>,
    setCategoriesList: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>
) => {
    await drinksService.getAll().then((res) => {
        setDrinks(res.data)
    })
    await drinksService.getAllCategories().then((res) => {
        setCategoriesList(res.data)
    })
}
