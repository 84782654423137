import SidebarWithHeader from 'admin/components/sidebar'
import AnnouncementTable from 'admin/components/tables/announcement/announcementTable'
import {
    deleteAnnouncement,
    getAllAnnouncement,
    postAnnouncement,
    updateAnnouncement,
} from 'admin/components/tables/announcement/functions'

const AdminAnnouncementPage = () => {
    return (
        <SidebarWithHeader>
            <AnnouncementTable
                getAllFunc={getAllAnnouncement}
                postFunc={postAnnouncement}
                updateFunc={updateAnnouncement}
                deleteFunc={deleteAnnouncement}
                title={'Szalag hirdetmény'}
            ></AnnouncementTable>
        </SidebarWithHeader>
    )
}

export default AdminAnnouncementPage
