import { Button } from '@chakra-ui/react'
import React from 'react'

interface Props {
    onClose?: () => void
}

const SubmitButtons = ({ onClose }: Props) => {
    return (
        <div>
            <Button background={'green.200'} _hover={{ background: 'green.400' }} mr={3} type="submit">
                Mentés
            </Button>
            <Button onClick={onClose}>Mégsem</Button>
        </div>
    )
}

export default SubmitButtons
