import { FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '@chakra-ui/react'
import SubmitButtons from 'admin/components/customModal/submitButtons'
import React, { useState } from 'react'
import { categoryProps } from 'types'

interface Props {
    onClose?: () => void
    modalName?: string
    updateForm?: (id: string, index: string, name: string, englishName: string) => void
    postForm?: (name: string, englishName: string) => void
    data?: categoryProps
}

const CategoryForm = ({ onClose, modalName, data, updateForm, postForm }: Props) => {
    const [name, setName] = useState(data?.name || '')
    const [englishName, setEnglishName] = useState(data?.english?.name || '')

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault()
        if (data) updateForm && updateForm(data._id, data.index, name, englishName)
        else postForm && postForm(name, englishName)
        onClose && onClose()
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ModalHeader>{modalName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Név</FormLabel>
                        <Input placeholder="Név" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol név</FormLabel>
                        <Input placeholder="Angol név" defaultValue={englishName} onChange={(e) => setEnglishName(e.target.value)} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <SubmitButtons onClose={onClose}></SubmitButtons>
                </ModalFooter>
            </form>
        </>
    )
}

export default CategoryForm
