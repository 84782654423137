import { HorizontalLineEvents } from 'style'
import { eventsProps } from 'types'

import { backendUrl } from '../../../utils'
import {
    CardContainerEvents,
    CardFull,
    CardImage,
    CardItemTextEvents,
    CardTitleEvents,
    CardWrapperEventsHorizontal,
    CardWrapperEventsVertical,
    Texture,
} from './Cards.style'

interface Props {
    data: eventsProps[] | undefined
}
const paperTexture = '/black-linen.png'

const EventsCards = ({ data }: Props) => {
    return (
        <>
            {data?.map((item, index) => {
                return (
                    <CardContainerEvents key={index}>
                        <CardFull margin="0% 5% 2% 5%">
                            <Texture src={paperTexture}>
                                <CardWrapperEventsHorizontal>
                                    <CardWrapperEventsVertical>
                                        <CardTitleEvents>{item.name}</CardTitleEvents>
                                        {item.place && <CardItemTextEvents fontWeight={'500'}>{`${item.place}`}</CardItemTextEvents>}
                                        {item.date && <CardItemTextEvents fontWeight={'300'}>{`${item.date}`}</CardItemTextEvents>}
                                        <HorizontalLineEvents></HorizontalLineEvents>
                                        {item.abstract && <CardItemTextEvents fontWeight={'300'}>{`${item.abstract}`}</CardItemTextEvents>}
                                        {item.description && (
                                            <CardItemTextEvents fontWeight={'300'}>{`${item.description}`}</CardItemTextEvents>
                                        )}
                                    </CardWrapperEventsVertical>
                                    <CardImage src={`${backendUrl}${item.image}`} alt="" />
                                </CardWrapperEventsHorizontal>
                            </Texture>
                        </CardFull>
                    </CardContainerEvents>
                )
            })}
        </>
    )
}

export default EventsCards
