import AdminGalleryPage from 'admin/components/PageTemplates/AdminGalleryPage'
import React from 'react'

import { ProtectedRoute } from '../../../../services/auth.service'

const ProtectedAdminGalleryPage = () => {
    return (
        <ProtectedRoute>
            <AdminGalleryPage></AdminGalleryPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminGalleryPage
