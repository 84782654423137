import React from 'react'

import { Banner, BannerCircle, Border, SimpleBackground, Texture } from './Frame.style'

interface Props {
    children: JSX.Element
}

const fecske_logo_full = '/fecske_logo_full.png'
const paperTexture = '/white_plaster_texture.jpg'

export const Frame = ({ children }: Props) => {
    return (
        <Border>
            <Banner>
                <BannerCircle>
                    <img src={fecske_logo_full} alt="fecske_logo_full" />
                </BannerCircle>
            </Banner>
            <Texture src={paperTexture}>{children}</Texture>
        </Border>
    )
}

export const FrameDark = ({ children }: Props) => {
    return (
        <Border>
            <SimpleBackground>{children}</SimpleBackground>
        </Border>
    )
}
