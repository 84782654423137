import styled from 'styled-components'

import { navbarZindex } from '../util'

export const AnnouncementContainer = styled.div`
    display: flex;
    top: 64px;
    position: fixed;
    width: 100%;
    height: 2.2rem;
    background: #ff0000;
    justify-content: center;
    align-items: center;
    margin: auto;
    z-index: ${navbarZindex - 1};
`
export const AnnouncementText = styled.div`
    font-size: 1.375rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
`
