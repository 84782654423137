import { getAllAnnouncement } from 'admin/components/tables/announcement/functions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { announcementProps } from 'types'

import { AnnouncementContainer, AnnouncementText } from './Announcement.style'

export const Announcement = () => {
    const [announcement, setAnnouncement] = useState<announcementProps[]>()

    useEffect(() => {
        getAllAnnouncement(setAnnouncement)
    }, [])

    const { i18n } = useTranslation()

    return (
        <>
            {announcement ? (
                announcement.map((item, index) => {
                    if (item.active)
                        return (
                            <AnnouncementContainer key={index}>
                                <AnnouncementText>{i18n.language === 'en' ? item.english.description : item.description}</AnnouncementText>
                            </AnnouncementContainer>
                        )
                    else return <AnnouncementContainer style={{ display: 'none' }}></AnnouncementContainer>
                })
            ) : (
                <AnnouncementContainer style={{ display: 'none' }}></AnnouncementContainer>
            )}
        </>
    )
}
