import AdminDrinksPage from 'admin/components/PageTemplates/AdminDrinksPage'
import React from 'react'

import { ProtectedRoute } from '../../../../services/auth.service'

const ProtectedAdminDrinksPage = () => {
    return (
        <ProtectedRoute>
            <AdminDrinksPage></AdminDrinksPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminDrinksPage
