import { Icon } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiArrowDown } from 'react-icons/fi'
import { BackgroundContainerColored, Info, InfoText } from 'style'
import { CurrentType, HoverAnimation } from 'types'

import {
    BackgroundImage,
    BackgroundWrapper,
    FecskeLanding,
    FecskeText,
    HoverContainerLeft,
    HoverContainerRight,
    KisfecskeText,
    LandingMouseGrid,
    RestaurantsContainer,
    VerticalDivider,
} from '../../LandingPage.styled'
import FecskeHover from '../svgs/FecskeHover'
import KisfecskeHover from '../svgs/KisfecskeHover'
import { fecskeHoverAnimationIn, fecskeHoverAnimationOut, kisfecskeHoverAnimationIn, kisfecskeHoverAnimationOut, timeout } from '../util'
import { MobileRestaurants } from './MobileRestaurants'

export const Restaurants = () => {
    const backgroundImg = '/landing_page_no_hover.webp'

    const [currentOpen, setCurrentOpen] = useState<CurrentType>('none')
    const [show, setShow] = useState('none')
    const [animation, setAnimation] = useState('')
    const [animationLine, setAnimationLine] = useState('')
    const [blur, setBlur] = useState('')

    const open = (animation: HoverAnimation) => {
        setAnimation(animation.base)
        setAnimationLine(animation.line)
        setBlur(animation.blur)
        setShow(animation.show)
        setCurrentOpen(animation.current)
    }

    const close = async (animation: HoverAnimation) => {
        setAnimation(animation.base)
        setAnimationLine(animation.line)
        setBlur(animation.blur)
        await timeout(1000)
        setShow(animation.show)
        setCurrentOpen(animation.current)
    }

    const handleClickFecske = () => {
        if (currentOpen === 'kisfecske') close(kisfecskeHoverAnimationOut)
        else open(fecskeHoverAnimationIn)
    }

    const handleClickKisfecske = async () => {
        if (currentOpen === 'fecske') close(fecskeHoverAnimationOut)
        else open(kisfecskeHoverAnimationIn)
    }
    const fecskeText = '/fecsketext.png'
    const kisfecskeText = '/kisfecsketext.png'
    const fecskeLanding = '/fecske_landing.png'

    const { t } = useTranslation()

    return (
        <>
            <Info>
                <Icon as={FiArrowDown} w={5} h={5} marginTop={-1} marginRight={2} color="white" />
                <InfoText>{t('chooseRestaurant')}</InfoText>
                <Icon as={FiArrowDown} w={5} h={5} marginTop={-1} marginLeft={2} color="white" />
            </Info>
            <BackgroundContainerColored>
                <RestaurantsContainer>
                    <BackgroundWrapper>
                        <BackgroundImage src={backgroundImg} animation={blur} />
                        <FecskeLanding src={fecskeLanding}></FecskeLanding>
                        <FecskeText src={fecskeText} animation={blur} />
                        <KisfecskeText src={kisfecskeText} animation={blur} />
                        <LandingMouseGrid>
                            <div onClick={handleClickFecske}></div>
                            <div onClick={handleClickKisfecske}> </div>
                        </LandingMouseGrid>
                        {currentOpen === 'fecske' && (
                            <HoverContainerLeft display={show} animation={animation}>
                                <FecskeHover></FecskeHover>
                            </HoverContainerLeft>
                        )}
                        {currentOpen === 'kisfecske' && (
                            <HoverContainerRight display={show} animation={animation}>
                                <KisfecskeHover></KisfecskeHover>
                            </HoverContainerRight>
                        )}
                        <VerticalDivider animation={animationLine}></VerticalDivider>
                    </BackgroundWrapper>
                </RestaurantsContainer>
                <MobileRestaurants></MobileRestaurants>
            </BackgroundContainerColored>
        </>
    )
}
