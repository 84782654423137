import {
    Badge,
    Container,
    HStack,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react'
import { DeleteDialog } from 'admin/components/customModal/deleteDialog'
import { CustomModalEdit } from 'admin/components/customModal/edit'
import { CustomModalNew } from 'admin/components/customModal/new'
import React, { useEffect, useReducer, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { TdStyle } from 'style'
import { categoryProps } from 'types'

import CategoryForm from './categoryForm'

interface Props {
    title: string
    getAllFunc: (setCategories: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>) => Promise<void>
    postFunc: (name: string, englishName: string) => void
    updateFunc: (id: string, index: string, name: string, englishName: string) => void
    deleteFunc: (id: string) => any
}

const CategoryTable = ({ getAllFunc, postFunc, updateFunc, deleteFunc, title }: Props) => {
    const [categories, setCategories] = useState<categoryProps[]>()

    const [refresh, forceUpdate] = useReducer((x) => x + 1, 0)
    const [data, setData] = useState<categoryProps>()

    const headers = ['Név', 'Angol név']
    const { isOpen, onOpen, onClose } = useDisclosure()

    const postHandler = async (description: string, englishDescription: string) => {
        try {
            await postFunc(description, englishDescription)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const updateHandler = async (id: string, index: string, description: string, englishDescription: string) => {
        try {
            await updateFunc(id, index, description, englishDescription)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const deleteHandler = async (delete_id: string) => {
        try {
            await deleteFunc(delete_id)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getAllFunc(setCategories)
    }, [getAllFunc, refresh])

    return (
        <>
            <Container maxW="100%" bg="white" rounded={'md'}>
                <HStack paddingTop={6} paddingBottom={6} justifyContent={'space-between'}>
                    <Text fontSize="xl" fontWeight={'bold'}>
                        {title}
                    </Text>
                    <CustomModalNew modalName="Kategória hozzáadása">
                        <CategoryForm postForm={postHandler}></CategoryForm>
                    </CustomModalNew>
                    <CustomModalEdit modalName="Kategória szerkesztése" onClose={onClose} isOpen={isOpen}>
                        <CategoryForm data={data} updateForm={updateHandler}></CategoryForm>
                    </CustomModalEdit>
                </HStack>
                <TableContainer>
                    <Table variant="striped" colorScheme="teal">
                        <Thead>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                                <Th>{<Badge colorScheme="blue">Módosítás</Badge>}</Th>
                                <Th>{<Badge colorScheme="red">Törlés</Badge>}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {categories &&
                                categories.map((item, index) => {
                                    return (
                                        <Tr key={index}>
                                            <Td style={TdStyle}>{item.name}</Td>
                                            <Td style={TdStyle}>{item.english?.name}</Td>
                                            <Td>
                                                <Icon
                                                    as={FiEdit2}
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-console
                                                        onOpen()
                                                        setData(item)
                                                    }}
                                                ></Icon>
                                            </Td>
                                            <Td>
                                                <DeleteDialog
                                                    title={'Kategória törlése'}
                                                    question={`Biztosan törölni szeretnéd a(z) ${item.name} elemet?`}
                                                    deleteItem={deleteHandler}
                                                    delete_id={item._id}
                                                ></DeleteDialog>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default CategoryTable
