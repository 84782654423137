import SidebarWithHeader from 'admin/components/sidebar'
import { deleteFunction, getAllFunction, postFunction, updateFunction } from 'admin/components/tables/shop/functions'
import ShopTable from 'admin/components/tables/shop/shopTable'
import React from 'react'

const AdminShopPage = () => {
    return (
        <SidebarWithHeader>
            <ShopTable
                getAllFunc={getAllFunction}
                postFunc={postFunction}
                updateFunc={updateFunction}
                deleteFunc={deleteFunction}
                title={'Bolt'}
            ></ShopTable>
        </SidebarWithHeader>
    )
}

export default AdminShopPage
