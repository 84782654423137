import {
    Checkbox,
    CheckboxGroup,
    Flex,
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Select,
} from '@chakra-ui/react'
import SubmitButtons from 'admin/components/customModal/submitButtons'
import React, { useState } from 'react'
import { allergensProps, categoryProps, foodProps } from 'types'

interface Props {
    onClose?: () => void
    modalName?: string
    categoriesList: categoryProps[]
    allergensList: allergensProps[]
    updateForm?: (
        id: string,
        index: string,
        name: string,
        englishName: string,
        price: string,
        category: string,
        allergens: string[]
    ) => void
    postForm?: (name: string, englishName: string, price: string, category: string, allergens: string[]) => void
    data?: foodProps
}

const FoodForm = ({ onClose, modalName, categoriesList, allergensList, data, updateForm, postForm }: Props) => {
    const [name, setName] = useState(data?.name || '')
    const [englishName, setEnglishName] = useState(data?.english.name || '')
    const [price, setPrice] = useState(data?.price || '')
    const [category, setCategory] = useState(data?.category._id || '')
    const [allergens, setAllergens] = useState<any[]>(
        data?.allergens.map((allergens) => {
            return allergens._id
        }) || ['62ef7d28b8fa5110c82b806c']
    )

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault()
        if (data) updateForm && updateForm(data._id, data.index, name, englishName, price, category, allergens)
        else postForm && postForm(name, englishName, price, category, allergens)
        onClose && onClose()
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ModalHeader>{modalName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Név</FormLabel>
                        <Input placeholder="Név" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol név</FormLabel>
                        <Input placeholder="Angol név" defaultValue={englishName} onChange={(e) => setEnglishName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Ár</FormLabel>
                        <Input placeholder="Ár" defaultValue={price} onChange={(e) => setPrice(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Kategóriák</FormLabel>
                        <Select value={category} placeholder="Válassz kategóriát" onChange={(e) => setCategory(e.target.value)}>
                            {categoriesList?.map((category, index) => {
                                return (
                                    <option key={index} value={category._id}>
                                        {category?.name}
                                    </option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Allergének</FormLabel>
                        <CheckboxGroup
                            isNative
                            value={allergens}
                            onChange={(value) => {
                                setAllergens(value)
                            }}
                        >
                            <Flex wrap={'wrap'} gap={'15px'}>
                                {allergensList?.map((allergen, index) => {
                                    return (
                                        <Checkbox value={allergen._id} key={index}>
                                            {allergen?.name}
                                        </Checkbox>
                                    )
                                })}
                            </Flex>
                        </CheckboxGroup>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <SubmitButtons onClose={onClose}></SubmitButtons>
                </ModalFooter>
            </form>
        </>
    )
}

export default FoodForm
