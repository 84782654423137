import Weekly from 'pages/WeeklyPage/components/Weekly'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from 'site/components/Footer'
import Hero from 'site/components/Hero'
import { Navbar } from 'site/components/Navbar'
import { Container } from 'style'
import { capitalizeFirstLetter } from 'utils'

export const WeeklyPage = () => {
    const { t } = useTranslation()

    return (
        <Container>
            <Navbar links={true}></Navbar>
            <Hero title={capitalizeFirstLetter(t('weekly'))}></Hero>
            <Weekly></Weekly>
            <Footer></Footer>
        </Container>
    )
}
