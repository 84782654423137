import { HStack, Image, VStack } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
    fecskeAdat,
    fecskeLogo,
    fecskeNyitvatartas,
    fecskeOpenings,
    kisfecskeAdat,
    kisFecskeNyitvatartas,
    kisFecskeOpenings,
} from '../../../pages/ContactPage/components/data'
import { FooterContainer, FooterH1, FooterP, FooterWrapper } from './Footer.style'
import FooterData from './FooterData'
import FooterOpenings from './FooterOpenings'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterDesktop></FooterDesktop>
                <FooterMobile></FooterMobile>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer

const FooterDesktop = () => {
    const { i18n, t } = useTranslation()

    const fecskeOpeningHours = i18n.language === 'en' ? fecskeOpenings : fecskeNyitvatartas
    const kisFecskeOpeningHours = i18n.language === 'en' ? kisFecskeOpenings : kisFecskeNyitvatartas
    return (
        <HStack
            display={{ base: 'none', md: 'flex' }}
            marginTop={'3.75rem'}
            marginBottom={'3.75rem'}
            alignItems={'unset'}
            width={'100%'}
            justifyContent={'space-between'}
        >
            <HStack>
                <VStack alignItems={'unset'} marginRight={{ base: '1.5rem', xl: '6.25rem' }}>
                    <FooterH1>Fecske Presszó</FooterH1>
                    <FooterData data={fecskeAdat}></FooterData>
                    <FooterOpenings data={fecskeOpeningHours}></FooterOpenings>
                </VStack>
                <VStack alignItems={'unset'}>
                    <FooterH1>Kis Fecske Presszó</FooterH1>
                    <FooterData data={kisfecskeAdat}></FooterData>
                    <FooterOpenings data={kisFecskeOpeningHours}></FooterOpenings>
                </VStack>
            </HStack>
            <VStack height={'100%'}>
                <Image src={fecskeLogo} alt="fecskeLogo" height={'12.5rem'} />
                <FooterP>{t('privacyPolicy')}</FooterP>
            </VStack>
        </HStack>
    )
}

const FooterMobile = () => {
    return (
        <VStack display={{ base: 'flex', md: 'none' }} alignItems={'flex-start'} margin={'auto'} marginTop={'3rem'}>
            <VStack alignItems={'unset'} marginRight={{ base: '1.5rem', xl: '6.25rem' }}>
                <FooterH1>Fecske Presszó</FooterH1>
                <FooterData data={fecskeAdat}></FooterData>
                <FooterOpenings data={fecskeNyitvatartas}></FooterOpenings>
            </VStack>
            <VStack alignItems={'unset'} marginTop={'3rem !important'}>
                <FooterH1>Kis Fecske Presszó</FooterH1>
                <FooterData data={kisfecskeAdat}></FooterData>
                <FooterOpenings data={kisFecskeNyitvatartas}></FooterOpenings>
            </VStack>
            <VStack alignSelf={'center'} marginTop={'1rem !important'} marginBottom={'3rem !important'}>
                <Image src={fecskeLogo} alt="fecskeLogo" height={'12.5rem'} />
                <FooterP>Általános szerződési feltételek</FooterP>
                <FooterP>Adatkezelési nyilatkozatok</FooterP>
            </VStack>
        </VStack>
    )
}
