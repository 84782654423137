import React from 'react'

import LoginContent from '../../../site/components/Auth/LoginContent'
import { AuthPage } from '../../AuthPage'

const LoginPage = () => {
    return (
        <AuthPage>
            <LoginContent></LoginContent>
        </AuthPage>
    )
}

export default LoginPage
