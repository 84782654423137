import styled from 'styled-components'

import { navbarZindex } from '../../../pages/LandingPage/components/util'

export const NavbarContainer = styled.div`
    position: fixed;
    background: #000;
    width: 100%;
    height: 64px;
    z-index: ${navbarZindex};

    @media (min-width: 481px) and (max-width: 767px) {
        width: 100vw;
    }
    @media (max-width: 480px) {
        width: 100vw;
    }
`

export const NavbarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 100%;
    @media (max-width: 1440px) {
    }

    @media (max-width: 1280px) {
        width: 95%;
    }

    @media (min-width: 481px) and (max-width: 767px) {
        height: 100%;
    }

    @media (max-width: 480px) {
        height: 100%;
    }
`

export const NavbarText = styled.div`
    color: #fff;
    font-size: 1.875rem;
    font-weight: 300;
    font-family: Josefin Sans;
    white-space: nowrap;
    overflow: hidden;
    @media (min-width: 481px) and (max-width: 767px) {
        display: none;
    }

    @media (max-width: 480px) {
        display: none;
    }
`

export const NavLink = styled.div`
    color: #fff;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: Josefin Sans;
    border-radius: 25px;
    border: 2px solid #e7d9cd;
    padding: 3px 15px 3px 15px;
    margin: 0 0.3125rem 0 0 !important;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: #000;
        background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(231, 217, 205, 1) 100%);
        cursor: pointer;
    }
`
export const NavMenuLink = styled.div`
    color: #fff;
    font-size: 1.125rem;
    font-weight: 300;
    font-family: Josefin Sans;
    padding-left: 0.625rem;

    &:hover {
        color: #000;
        background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(231, 217, 205, 1) 100%);
        cursor: pointer;
    }
`
