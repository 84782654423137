import { HoverAnimation } from 'types'

export const navbarZindex = 200

export function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
}

export const fecskeHoverAnimationIn: HoverAnimation = {
    base: 'slideInFecskeHover 1s linear',
    line: 'slideInFecskeHoverVericalLine 0.2s linear 0.8s',
    blur: 'blurIn 0.3s linear 0.7s',
    show: 'flex',
    current: 'fecske',
}

export const fecskeHoverAnimationOut: HoverAnimation = {
    base: 'slideOutFecskeHover 1s linear',
    line: 'slideOutFecskeHoverVericalLine 0.2s linear',
    blur: 'blurOut 0.5s linear',
    show: 'none',
    current: 'none',
}

export const kisfecskeHoverAnimationIn: HoverAnimation = {
    base: 'slideInKisfecskeHover 1s linear',
    line: 'slideInKisfecskeHoverVericalLine 0.2s linear 0.8s',
    blur: 'blurIn 0.3s linear 0.7s',
    show: 'flex',
    current: 'kisfecske',
}

export const kisfecskeHoverAnimationOut: HoverAnimation = {
    base: 'slideOutKisfecskeHover 1s linear',
    line: 'slideOutKisfecskeHoverVericalLine 0.2s linear',
    blur: 'blurOut 0.5s linear',
    show: 'none',
    current: 'none',
}
