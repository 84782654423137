import { Box, BoxProps, CloseButton, Flex, FlexProps, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react'
import React, { ReactText } from 'react'
import { IconType } from 'react-icons'
import { FiAlertCircle, FiAlertTriangle, FiCalendar, FiClipboard, FiImage, FiShoppingBag, FiSliders } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import { getRestaurant } from 'restaurant'
import { HorizontalLineSidebar } from 'style'

interface SidebarProps extends BoxProps {
    onClose: () => void
}
interface LinkItemProps {
    name: string
    path: string
    icon: IconType
}

export const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const { pathname } = useLocation()
    const restaurant = getRestaurant(pathname)

    const LinkItemsFood: Array<LinkItemProps> = [
        { name: 'Heti menü', path: `/admin/${restaurant}/weekly`, icon: FiCalendar },
        { name: 'Étlap', path: `/admin/${restaurant}/menu`, icon: FiClipboard },
        { name: 'Itallap', path: `/admin/${restaurant}/drinks`, icon: FiClipboard },
        { name: 'Étel kategóriák', path: `/admin/${restaurant}/categories/food`, icon: FiSliders },
        { name: 'Ital kategóriák', path: `/admin/${restaurant}/categories/drinks`, icon: FiSliders },
    ]

    const LinkItemsOther: Array<LinkItemProps> = [
        { name: 'Galéria', path: `/admin/common/gallery`, icon: FiImage },
        { name: 'Események', path: `/admin/common/events`, icon: FiCalendar },
        { name: 'Bolt', path: `/admin/common/shop`, icon: FiShoppingBag },
        { name: 'Szalag hirdetmény', path: `/admin/common/announcement`, icon: FiAlertTriangle },
        { name: 'Hirdetmények', path: `/admin/common/news`, icon: FiAlertCircle },
    ]
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            overflow={'scroll'}
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    Admin
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            <HorizontalLineSidebar></HorizontalLineSidebar>
            <Flex alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="md" fontFamily="monospace" fontWeight="bold">
                    {restaurant}
                </Text>
            </Flex>
            <HorizontalLineSidebar></HorizontalLineSidebar>
            {LinkItemsFood.map((link) => (
                <NavItem key={link.name} icon={link.icon} path={link.path}>
                    {link.name}
                </NavItem>
            ))}
            <HorizontalLineSidebar></HorizontalLineSidebar>
            <Flex alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="md" fontFamily="monospace" fontWeight="bold">
                    Közös elemek
                </Text>
            </Flex>
            <HorizontalLineSidebar></HorizontalLineSidebar>
            {LinkItemsOther.map((link) => (
                <NavItem key={link.name} icon={link.icon} path={link.path}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    )
}

interface NavItemProps extends FlexProps {
    icon: IconType
    path: string
    children: ReactText
}
const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
    return (
        <Link href={path} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    )
}
