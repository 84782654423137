import axios from 'axios'

import { backendUrlBase } from '../utils'

export const isBrowser = (): boolean => {
    return typeof window !== 'undefined'
}

export const nextLocalStorage = (): Storage | void => {
    if (isBrowser()) {
        return window.localStorage
    }
}

export default axios.create({
    baseURL: `${backendUrlBase}`,
    headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${nextLocalStorage()?.getItem('token')}`,
    },
})
