import AdminNewsPage from 'admin/components/PageTemplates/AdminNewsPage'
import React from 'react'

import { ProtectedRoute } from '../../../../services/auth.service'

const ProtectedAdminNewsPage = () => {
    return (
        <ProtectedRoute>
            <AdminNewsPage></AdminNewsPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminNewsPage
