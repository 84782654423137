import AdminWeeklyPage from 'admin/components/PageTemplates/AdminWeeklyPage'
import React from 'react'

import { ProtectedRoute } from '../../../../services/auth.service'

const ProtectedAdminWeeklyPage = () => {
    return (
        <ProtectedRoute>
            <AdminWeeklyPage></AdminWeeklyPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminWeeklyPage
