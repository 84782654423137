import AdminEventsPage from 'admin/components/PageTemplates/AdminEventsPage'
import React from 'react'

import { ProtectedRoute } from '../../../../services/auth.service'

const ProtectedAdminEventsPage = () => {
    return (
        <ProtectedRoute>
            <AdminEventsPage></AdminEventsPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminEventsPage
