import * as drinksService from 'services/drinks.service'
import { categoryProps, drinksProps, PriceArrayProps } from 'types'

export const getAllDrinks = async (
    setDrinks: React.Dispatch<React.SetStateAction<drinksProps[] | undefined>>,
    setCategoriesList: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>
): Promise<any> => {
    try {
        const drinksResponse = await drinksService.getAll()
        setDrinks(drinksResponse.data)

        const categoriesResponse = await drinksService.getAllCategories()
        setCategoriesList(categoriesResponse.data)

        return { drinksResponse, categoriesResponse }
    } catch (error) {
        throw error
    }
}

export const updateDrinksForm = async (
    id: string,
    index: string,
    name: string,
    description: string,
    englishName: string,
    englishDescription: string,
    priceArray: PriceArrayProps[],
    category: string
): Promise<any> => {
    try {
        const res = await drinksService.update({
            _id: id,
            index: index,
            name: name,
            description: description,
            english: { name: englishName, description: englishDescription },
            priceArray: priceArray,
            category: category,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const postDrinksForm = async (
    name: string,
    description: string,
    englishName: string,
    englishDescription: string,
    priceArray: PriceArrayProps[],
    category: string
): Promise<any> => {
    try {
        const res = await drinksService.post({
            index: 1,
            name: name,
            description: description,
            english: { name: englishName, description: englishDescription },
            priceArray: priceArray,
            category: category,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const deleteDrinksItem = async (id: string): Promise<any> => {
    try {
        const res = await drinksService.deleteItem({ id })
        return res
    } catch (error) {
        throw error
    }
}
