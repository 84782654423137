import { HStack } from '@chakra-ui/react'
import React from 'react'
import { Navbar } from 'site/components/Navbar'
import { BackgroundContainerColored, ContainerFull } from 'style'

export const AuthPage = ({ children }: any) => {
    return (
        <>
            <ContainerFull>
                <Navbar links={false}></Navbar>
                <HStack alignItems={'center'} width="100%" justifyContent={'center'}>
                    <BackgroundContainerColored>{children}</BackgroundContainerColored>
                </HStack>
            </ContainerFull>
        </>
    )
}
