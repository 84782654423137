import React from 'react'

const TextSVG = () => {
    return (
        <>
            <text
                transform="translate(58.86 180.79)"
                style={{
                    isolation: 'isolate',
                    fontSize: 120,
                    fontFamily: 'JosefinSans-Light,Josefin Sans',
                    fontWeight: 300,
                    fill: '#fff',
                }}
            >
                <tspan
                    style={{
                        letterSpacing: '-.07em',
                    }}
                >
                    {'F'}
                </tspan>
                <tspan x={61.2} y={0}>
                    {'ecs'}
                </tspan>
                <tspan
                    x={215.76}
                    y={0}
                    style={{
                        letterSpacing: '-.02em',
                    }}
                >
                    {'k'}
                </tspan>
                <tspan x={267.36} y={0}>
                    {'e'}
                </tspan>
            </text>
        </>
    )
}

export default TextSVG
