import styled from 'styled-components'

export const TdStyle = { textOverflow: 'ellipsis', maxWidth: '6.25rem', overflow: 'hidden', fontSize: '0.9rem' }

export const Container = styled.div`
    width: 100%;
    height: auto;
`

export const ContainerFull = styled.div`
    display: flex;
    width: 100%;
    min-height: 100vh;
    height: auto;
`

export const BackgroundContainer = styled.div<{ src?: string }>`
    width: 100%;
    height: min-content;
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 2rem;
    padding-bottom: 2rem;
`

export const BackgroundContainerColored = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: radial-gradient(
        circle,
        rgba(232, 221, 181, 1) 0%,
        rgba(224, 199, 132, 1) 33%,
        rgba(197, 179, 125, 1) 73%,
        rgba(194, 170, 106, 1) 81%,
        rgba(161, 129, 68, 1) 100%
    );
`

export const PlaceHolder = styled.div`
    background: #fff;
    opacity: 0;
    height: 3.125rem;
    width: 100%;

    @media (max-width: 767px) {
        height: 6rem;
    }
`

export const PlaceHolderAllergen = styled.div`
    background: #fff;
    opacity: 0;
    height: 3.125rem;
    width: 100%;

    @media (max-width: 767px) {
        height: 2rem;
    }
`
export const PlaceHolderNavbar = styled.div`
    background: #fff;
    opacity: 0;
    height: 64px;
    width: 100%;
`

export const HorizontalLine = styled.hr`
    border-top: 3px double #fff;
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
    width: 100%;
`

export const HorizontalLineMenu = styled.hr`
    border-top: 1px solid #fff;
    margin-bottom: 0.625rem !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`
export const HorizontalLineEvents = styled.hr`
    border-top: 1px solid #fff;
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`

export const HorizontalLineSidebar = styled.hr`
    border-top: 4px double #808080;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`

export const HeroDivider = styled.div`
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 100%;
    background: linear-gradient(
        210deg,
        rgba(56, 33, 12, 1) 0%,
        rgba(52, 30, 10, 1) 25%,
        rgba(45, 25, 8, 1) 50%,
        rgba(36, 19, 5, 1) 75%,
        rgba(30, 15, 3, 1) 100%
    );
    box-shadow: inset 0 0 10px 1px #000000;
`

export const Info = styled.div`
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 100%;
    background: #200f01;
    box-shadow: 0 3px 10px #000000;
`

export const InfoText = styled.p`
    font-size: 1.375rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
`
