import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Icon,
    useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { FiX } from 'react-icons/fi'

interface Props {
    title: string
    question: string
    deleteItem: any
    delete_id: string
    forceUpdate?: React.DispatchWithoutAction
    image?: string
}

export const DeleteDialog = ({ title, question, deleteItem, delete_id, image, forceUpdate }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<any>()

    return (
        <>
            <Icon color={'red'} boxSize={'1em'} as={FiX} onClick={onOpen}></Icon>

            <AlertDialog motionPreset="slideInBottom" isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {title}
                        </AlertDialogHeader>

                        <AlertDialogBody>{question}</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Mégsem
                            </Button>
                            {image ? (
                                <Button
                                    colorScheme="red"
                                    onClick={async () => {
                                        await deleteItem(delete_id, image)
                                        onClose()
                                    }}
                                    ml={3}
                                >
                                    Törlés
                                </Button>
                            ) : (
                                <Button
                                    colorScheme="red"
                                    onClick={async () => {
                                        await deleteItem(delete_id)
                                        onClose()
                                    }}
                                    ml={3}
                                >
                                    Törlés
                                </Button>
                            )}
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
