import * as service from 'services/news.service'
import { newsProps } from 'types'
import { createFormData } from 'utils'

export const getAllFunction = async (setState: React.Dispatch<React.SetStateAction<newsProps[] | undefined>>) => {
    try {
        const res = await service.getAll()
        setState(res.data)
    } catch (error) {
        throw error
    }
}

export const updateFunction = async () => {
    try {
        const res = await service.update({})
        return res
    } catch (error) {
        throw error
    }
}

export const postFunction = async (
    name: string,
    englishName: string,
    abstract: string,
    englishAbstract: string,
    description: string,
    englishDescription: string,
    link: string,
    imageFile: any
): Promise<void> => {
    try {
        const formData = createFormData({
            name,
            englishName,
            abstract,
            englishAbstract,
            description,
            englishDescription,
            link,
            image: imageFile,
        })

        await service.post(formData)
    } catch (error) {
        throw error
    }
}

export const deleteFunction = async (id: string, image: string) => {
    try {
        const res = await service.deleteItem({ id, image })
        return res
    } catch (error) {
        throw error
    }
}
