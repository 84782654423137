import styled from 'styled-components'

export const ContactContainer = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
`
export const ContactH1 = styled.p`
    font-size: 2.5rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
    @media (max-width: 767px) {
        font-size: 1.75rem;
    }
`

export const ContactP = styled.p`
    font-size: 1.5625rem;
    font-weight: 300;
    font-family: Josefin Sans;
    color: #fff;

    @media (max-width: 767px) {
        font-size: 1rem;
    }
`
export const ContactPHover = styled.p`
    font-size: 1.5625rem;
    font-weight: 300;
    font-family: Josefin Sans;
    color: #fff;

    &:hover {
        color: #e7d9cd;
    }
    @media (max-width: 767px) {
        font-size: 1rem;
        text-align: center;
    }
`
