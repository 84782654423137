import { VStack } from '@chakra-ui/react'
import React from 'react'
import { FrameDark } from 'site/components/Frame'
import { BackgroundContainerColored, HorizontalLine, PlaceHolder } from 'style'

import { ContactContainer, ContactH1 } from './contact.style'
import ContactData from './ContactData'
import { fecskeAdat, kisfecskeAdat } from './data'

const Contact = () => {
    return (
        <BackgroundContainerColored>
            <FrameDark>
                <ContactContainer>
                    <PlaceHolder></PlaceHolder>
                    <VStack justifyContent={'center'} margin={'auto'}>
                        <VStack>
                            <ContactH1>Fecske Presszó</ContactH1>
                            <ContactData data={fecskeAdat}></ContactData>
                        </VStack>
                        <HorizontalLine></HorizontalLine>
                        <VStack>
                            <ContactH1>Kis Fecske Presszó</ContactH1>
                            <ContactData data={kisfecskeAdat}></ContactData>
                        </VStack>
                    </VStack>
                    <PlaceHolder></PlaceHolder>
                </ContactContainer>
            </FrameDark>
        </BackgroundContainerColored>
    )
}

export default Contact
