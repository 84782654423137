import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as allergenService from 'services/allergen.service'
import { PlaceHolderAllergen } from 'style'
import { allergensProps } from 'types'

import { FrameDark } from '../Frame'
import { AllergenesContainer, AllergenesContent, Text } from './Allergenes.style'

const Allergenes = () => {
    const [allergenes, getAllergenes] = useState<allergensProps[]>()

    const { i18n } = useTranslation()

    useEffect(() => {
        async function fetchData() {
            await allergenService.getAll().then((res) => {
                getAllergenes(res.data)
            })
        }
        fetchData()
    }, [])

    return (
        <AllergenesContainer>
            <FrameDark>
                <AllergenesContent>
                    <PlaceHolderAllergen></PlaceHolderAllergen>
                    <Flex
                        marginLeft={{ base: '1rem', md: '3.125rem' }}
                        marginRight={{ base: '1rem', md: '3.125rem' }}
                        gap={{ base: '0.75rem', md: '1.25rem' }}
                        wrap={'wrap'}
                        justifyContent={'space-around'}
                    >
                        {allergenes &&
                            allergenes.map((allergen, index) => {
                                if (index !== 0)
                                    return (
                                        <div key={index}>
                                            <Text>
                                                {i18n.language === 'en'
                                                    ? `${allergen.english?.short} - ${allergen.english?.name}`
                                                    : `${allergen.short} - ${allergen.name}`}
                                            </Text>
                                        </div>
                                    )
                                return <div key={index}></div>
                            })}
                    </Flex>
                    <PlaceHolderAllergen></PlaceHolderAllergen>
                </AllergenesContent>
            </FrameDark>
        </AllergenesContainer>
    )
}

export default Allergenes
