import SidebarWithHeader from 'admin/components/sidebar'
import { deleteGallery, getAllGallery, postGallery, updateGallery } from 'admin/components/tables/gallery/functions'
import GalleryTable from 'admin/components/tables/gallery/galleryTable'
import React from 'react'

const AdminGalleryPage = () => {
    return (
        <SidebarWithHeader>
            <GalleryTable
                getAllFunc={getAllGallery}
                postFunc={postGallery}
                updateFunc={updateGallery}
                deleteFunc={deleteGallery}
                title={'Galéria'}
            ></GalleryTable>
        </SidebarWithHeader>
    )
}

export default AdminGalleryPage
