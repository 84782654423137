import { getAllFunction } from 'admin/components/tables/events/functions'
import React, { useEffect, useState } from 'react'
import EventsCards from 'site/components/Cards/EventsCards'
import { BackgroundContainerColored } from 'style'
import { eventsProps } from 'types'

const Events = () => {
    const [events, setEvents] = useState<eventsProps[]>()

    useEffect(() => {
        getAllFunction(setEvents)
    }, [])

    return (
        <BackgroundContainerColored>
            <EventsCards data={events}></EventsCards>
        </BackgroundContainerColored>
    )
}

export default Events
