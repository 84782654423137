import React from 'react'
import { HeroDivider } from 'style'

import { HeroContainer, HeroDesign, HeroText } from './Hero.style'

interface Props {
    title: string
}

const Hero = ({ title }: Props) => {
    const heroFecske = '/hero_fecske.png'

    return (
        <>
            <HeroContainer>
                <HeroDesign src={heroFecske}></HeroDesign>
                <HeroText>{title}</HeroText>
            </HeroContainer>
            <HeroDivider></HeroDivider>
        </>
    )
}

export default Hero
