import * as weeklyService from 'services/weekly.service'
import { categoryProps, foodProps } from 'types'

export const getAllWeekly = async (
    setFood: React.Dispatch<React.SetStateAction<foodProps[] | undefined>>,
    setCategoriesList: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>
) => {
    await weeklyService.getAll().then((res) => {
        setFood(res.data)
    })
    await weeklyService.getAllCategories().then((res) => {
        setCategoriesList(res.data)
    })
}
