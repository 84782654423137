import styled from 'styled-components'

export const AuthContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
`
export const AuthCard = styled.div`
    background: #200f01;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: min-content;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    width: 50%;
    border-radius: 10px;
    @media (max-width: 767px) {
        width: 90%;
    }
`

export const AuthCardWrapper = styled.div`
    height: 70%;
    width: 80%;
    margin: auto;
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: auto;
    width: 100%;
    margin: auto;
    @media (max-width: 767px) {
        flex-direction: column;
        gap: 0rem;
    }
`

export const TextH1 = styled.p`
    font-size: 2.25rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
`
