import styled from 'styled-components'

export const LandingMouseGrid = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 48vw 48vw;
    cursor: pointer;
    height: 100vh;
`

export const HoverContainerLeft = styled.div<{ display: string; animation: string }>`
    display: ${({ display }) => display};
    position: absolute;
    left: 0;
    top: 0;
    width: 64.6%;
    animation: ${({ animation }) => animation};
`

export const HoverContainerRight = styled.div<{ display: string; animation: string }>`
    display: ${({ display }) => display};
    position: absolute;
    right: 0;
    top: 0;
    width: 64.6%;
    animation: ${({ animation }) => animation};
`

export const BottomText = styled.p`
    position: absolute;
    color: #fff;
    font-weight: 200;
    font-size: 120px;
`

export const BackgroundWrapper = styled.div`
    position: relative;
    width: 80%;
    height: auto;
    margin: auto;
    overflow-y: hidden;
    overflow: hidden;
    border-width: 4.375rem;
    border-image: url(/fecske_border.png) 34% 33.5% repeat;
    border-style: solid;
    box-shadow: 0 3px 10px #000000;
`

export const BackgroundImage = styled.img<{ animation?: string }>`
    width: 100%;
    height: 100%;
    cursor: pointer;
    animation: ${({ animation }) => animation};
    animation-fill-mode: forwards;
`

export const FecskeLanding = styled.img`
    position: absolute;
    width: 100vw;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

export const FecskeText = styled.img<{ animation?: string }>`
    position: absolute;
    width: 25vw;
    height: auto;
    bottom: 0;
    animation: ${({ animation }) => animation};
    animation-fill-mode: forwards;
`

export const KisfecskeText = styled.img<{ animation?: string }>`
    position: absolute;
    width: 25vw;
    height: auto;
    bottom: 0;
    right: 0;
    animation: ${({ animation }) => animation};
    animation-fill-mode: forwards;
`

export const VerticalDivider = styled.div<{ animation: string }>`
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    border-left: thick solid #fff;
    animation: ${({ animation }) => animation};
    animation-fill-mode: forwards;
`

export const RestaurantsContainer = styled.div`
    @media (max-width: 767px) {
        display: none;
    }
`

export const MobileContainer = styled.div`
    display: none;
    @media (max-width: 767px) {
        display: inline;
        height: auto;
        width: 100%;
    }
`

export const MobileContainerWrapper = styled.div`
    display: flex;
    height: 10rem;
    margin: 0;
    width: 100%;
    justify-content: center;
    background: linear-gradient(45deg, rgba(51, 29, 10, 1) 0%, rgba(59, 36, 15, 1) 25%, rgba(79, 51, 27, 1) 65%, rgba(85, 56, 30, 1) 100%);
`

export const MobileWrapperVertical = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0;
    width: 100%;
`

export const MobileWrapperHorizontal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    margin: 0;
    width: 100%;
`

export const MobileTitle = styled.p`
    font-size: 2rem;
    font-weight: 500;
    font-family: Josefin Sans;
    color: #fff;
    margin-bottom: 1rem;
`
export const MobileButton = styled.p`
    color: #fff;
    background: #200f01;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: Josefin Sans;
    border-radius: 10px;
    border: 1px solid #e7d9cd;
    padding: 3px 15px 3px 15px;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: #200f01;
        background: #fff;
    }
`
