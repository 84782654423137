import { Box, HStack, IconButton, Image, Link, Stack, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoClose, IoMenuSharp } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { getRestaurant } from 'restaurant'
import { NavbarLinkProps } from 'types'

import { NavbarContainer, NavbarText, NavbarWrapper, NavLink } from './Navbar.style'
import NavbarHover from './NavbarHover'

interface Props {
    links: boolean
}

export const Navbar = ({ links }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation()
    const { pathname } = useLocation()
    const restaurant = getRestaurant(pathname)
    const fecskeLogo = '/fecske_logo_crop.png'

    const Links: Array<NavbarLinkProps> = [
        { name: t('menu'), path: `etlap`, multiple: true },
        { name: t('drinks'), path: `itallap`, multiple: true },
        { name: t('weekly'), path: `hetimenu`, multiple: true },
        { name: t('events'), path: `/esemenyek`, multiple: false },
        { name: t('gallery'), path: `/galeria`, multiple: false },
        { name: t('contact'), path: `/kapcsolat`, multiple: false },
    ]

    return (
        <NavbarContainer>
            <NavbarWrapper>
                <HStack spacing={8} alignItems={'center'}>
                    <Link href="/">
                        <Image src={fecskeLogo} alt="fecskeLogo" height={'4rem'} />
                    </Link>
                    {links && restaurant === 'fecske' && <NavbarText>Fecske Presszó</NavbarText>}
                    {links && restaurant === 'kisfecske' && <NavbarText>Kis Fecske Presszó</NavbarText>}
                </HStack>
                <HStack as={'nav'} spacing={4} display={{ base: 'none', xl: 'flex' }}>
                    {links &&
                        Links.map((link) => {
                            if (link.multiple) return <NavbarHover key={link.name} link={link} />
                            else
                                return (
                                    <Link key={link.name} href={link.path} style={{ textDecoration: 'none' }}>
                                        <NavLink key={link.name}>{link.name}</NavLink>
                                    </Link>
                                )
                        })}
                    <Link style={{ textDecoration: 'none' }}>
                        <NavLink
                            key={'language'}
                            onClick={() => {
                                i18n.language === 'en' ? i18n.changeLanguage('hu') : i18n.changeLanguage('en')
                            }}
                        >
                            {`language: ${i18n.language}`}
                        </NavLink>
                    </Link>
                </HStack>
                <IconButton
                    icon={isOpen ? <IoClose /> : <IoMenuSharp />}
                    background={'white !important'}
                    display={{ xl: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                    aria-label={'Menu'}
                    fontSize="30px"
                    paddingLeft={'0.3rem'}
                />

                {isOpen ? (
                    <Box
                        pb={4}
                        display={{ xl: 'none' }}
                        top={'56px'}
                        right={'0px'}
                        position={'absolute'}
                        background={'black'}
                        padding={'2rem'}
                        borderRadius={'8px'}
                    >
                        <Stack as={'nav'} spacing={4}>
                            {links &&
                                Links.map((link) => {
                                    if (link.multiple) return <NavbarHover key={link.name} link={link} />
                                    else
                                        return (
                                            <Link key={link.name} href={link.path} style={{ textDecoration: 'none' }}>
                                                <NavLink key={link.name}>{link.name}</NavLink>
                                            </Link>
                                        )
                                })}
                            <Link style={{ textDecoration: 'none' }}>
                                <NavLink
                                    key={'language'}
                                    onClick={() => {
                                        i18n.language === 'en' ? i18n.changeLanguage('hu') : i18n.changeLanguage('en')
                                    }}
                                >
                                    {i18n.language}
                                </NavLink>
                            </Link>
                        </Stack>
                    </Box>
                ) : null}
            </NavbarWrapper>
        </NavbarContainer>
    )
}
