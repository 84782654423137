import { Badge, Container, HStack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { DeleteDialog } from 'admin/components/customModal/deleteDialog'
import { CustomModalEdit } from 'admin/components/customModal/edit'
import { CustomModalNew } from 'admin/components/customModal/new'
import { AxiosResponse } from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import { TdStyle } from 'style'
import { shopProps } from 'types'

import { backendUrl } from '../../../../utils'
import ShopForm from './shopForm'

interface Props {
    title: string
    getAllFunc: (setState: React.Dispatch<React.SetStateAction<shopProps[] | undefined>>) => Promise<void>
    postFunc: (name: string, englishName: string, price: string, salePrice: string, onSale: string, imageFile: any) => Promise<void>
    updateFunc: () => void
    deleteFunc: (id: string, image: string) => Promise<AxiosResponse<any, any>>
}

const NewsTable = ({ getAllFunc, postFunc, updateFunc, deleteFunc, title }: Props) => {
    const [news, setNews] = useState<shopProps[]>()

    const [refresh, forceUpdate] = useReducer((x) => x + 1, 0)
    const [data] = useState<shopProps>()

    const headers = ['Név', 'Angol név', 'Ár', 'Kedvezményes ár', 'Akciós', 'Kép']
    const { isOpen, onClose } = useDisclosure()

    useEffect(() => {
        getAllFunc(setNews)
    }, [getAllFunc, refresh])

    return (
        <>
            <Container maxW="100%" bg="white" rounded={'md'}>
                <HStack paddingTop={6} paddingBottom={6} justifyContent={'space-between'}>
                    <Text fontSize="xl" fontWeight={'bold'}>
                        {title}
                    </Text>
                    <CustomModalNew modalName={`${title} hozzáadása`}>
                        <ShopForm forceUpdate={forceUpdate} postForm={postFunc}></ShopForm>
                    </CustomModalNew>
                    <CustomModalEdit modalName={`${title} szerkesztése`} onClose={onClose} isOpen={isOpen}>
                        <ShopForm forceUpdate={forceUpdate} data={data} updateForm={updateFunc}></ShopForm>
                    </CustomModalEdit>
                </HStack>
                <TableContainer>
                    <Table variant="striped" colorScheme="teal">
                        <Thead>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                                <Th>{<Badge colorScheme="red">Törlés</Badge>}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {news &&
                                news.map((item, index) => {
                                    return (
                                        <Tr key={index}>
                                            <Td style={TdStyle}>{item.name}</Td>
                                            <Td style={TdStyle}>{item.english?.name}</Td>
                                            <Td style={TdStyle}>{item.price}</Td>
                                            <Td style={TdStyle}>{item.salePrice}</Td>
                                            <Td style={TdStyle}>{item.onSale}</Td>
                                            <Td style={TdStyle}>
                                                <img src={`${backendUrl}${item.image}`} alt="" style={{ maxWidth: '3rem' }} />
                                            </Td>

                                            <Td>
                                                <DeleteDialog
                                                    title={'Kategória törlése'}
                                                    question={`Biztosan törölni szeretnéd a(z) ${item.name} elemet?`}
                                                    deleteItem={deleteFunc}
                                                    delete_id={item._id}
                                                    image={item.image}
                                                    forceUpdate={forceUpdate}
                                                ></DeleteDialog>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default NewsTable
