import * as drinksCategoryService from 'services/drinksCategory.service'
import { categoryProps } from 'types'

export const getAllDrinksCategories = async (
    setDrinksCategory: React.Dispatch<React.SetStateAction<categoryProps[] | undefined>>
): Promise<void> => {
    try {
        const res = await drinksCategoryService.getAll()
        setDrinksCategory(res.data)
    } catch (error) {
        throw error
    }
}

export const updateDrinksCategory = async (id: string, index: string, name: string, englishName: string): Promise<any> => {
    try {
        const res = await drinksCategoryService.update({
            _id: id,
            index: index,
            name: name,
            english: { name: englishName },
        })

        return res
    } catch (error) {
        throw error
    }
}

export const postDrinksCategory = async (name: string, englishName: string): Promise<any> => {
    try {
        const res = await drinksCategoryService.post({
            index: 1,
            name: name,
            english: { name: englishName },
        })

        return res
    } catch (error) {
        throw error
    }
}

export const deleteDrinksCategory = async (id: string): Promise<any> => {
    try {
        const res = await drinksCategoryService.deleteItem({ id })

        return res
    } catch (error) {
        throw error
    }
}
