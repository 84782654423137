import http from './http-common'

export const post = async (data: any) => {
    return await http.post(`common/announcement`, data).then((res) => {
        return res
    })
}

export const deleteItem = (data: { id: any }) => {
    return http.delete(`common/announcement/${data.id}`).then((res) => {
        return res
    })
}

export const update = async (data: any) => {
    return await http.put(`/common/announcement/${data._id}`, data).then((res) => {
        return res
    })
}

export const getAll = async () => {
    return await http.get(`common/announcement`).then((res) => {
        return res
    })
}
