import AdminAnnouncementPage from 'admin/components/PageTemplates/AdminAnnouncementPage'
import React from 'react'

import { ProtectedRoute } from '../../../../services/auth.service'

const ProtectedAdminAnnouncementPage = () => {
    return (
        <ProtectedRoute>
            <AdminAnnouncementPage></AdminAnnouncementPage>
        </ProtectedRoute>
    )
}

export default ProtectedAdminAnnouncementPage
