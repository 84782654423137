import * as galleryService from 'services/gallery.service'
import { galleryProps } from 'types'
import { createFormData } from 'utils'

export const getAllGallery = async (setState: React.Dispatch<React.SetStateAction<galleryProps[] | undefined>>) => {
    try {
        const res = await galleryService.getAll()
        setState(res.data)
    } catch (error) {
        throw error
    }
}

export const updateGallery = async (id: string, name: string, englishName: string) => {
    try {
        const res = await galleryService.update({
            _id: id,
            name: name,
            englishName: englishName,
        })
        return res
    } catch (error) {
        throw error
    }
}

export const postGallery = async (name: string, englishName: string, imageFile: any) => {
    try {
        const formData = createFormData({
            name: name,
            englishName: englishName,
            image: imageFile,
        })

        const res = await galleryService.post(formData)
        return res
    } catch (error) {
        throw error
    }
}
export const deleteGallery = async (id: string, image?: string) => {
    try {
        const res = await galleryService.deleteItem({ id, image })
        return res
    } catch (error) {
        throw error
    }
}
