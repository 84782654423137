import http from './http-common'

export const post = async (data: any) => {
    return await http
        .post(`common/news`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            return res
        })
}

export const deleteItem = (data: any) => {
    return http.post(`common/news/${data.id}`, data).then((res) => {
        return res
    })
}

export const update = async (data: any) => {
    return await http.put(`/common/news/${data._id}`, data).then((res) => {
        return res
    })
}

export const getAll = async () => {
    return await http.get(`common/news`).then((res) => {
        return res
    })
}
