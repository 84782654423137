import { HStack, Link } from '@chakra-ui/react'
import React from 'react'

import { ContactP, ContactPHover } from './contact.style'

const ContactData = ({ data }: any) => {
    return (
        <>
            <HStack>
                <Link href={data.maps}>
                    <ContactPHover>{data.address}</ContactPHover>
                </Link>
            </HStack>
            <HStack>
                <ContactP>{data.phone}</ContactP>
            </HStack>
            <HStack>
                <ContactP>{data.email}</ContactP>
            </HStack>
        </>
    )
}

export default ContactData
