import React from 'react'
import { useTranslation } from 'react-i18next'
import { PlaceHolder } from 'style'
import { categoryProps, foodProps } from 'types'

import { CardContainer, CardFull, CardItem, CardItemText, CardTitle, CardWrapper, Texture, TextWrapper } from './Cards.style'

interface Props {
    data: foodProps[] | undefined
    categories: categoryProps[] | undefined
}
const paperTexture = '/black-linen.png'

const MenuCardsFood = ({ data, categories }: Props) => {
    const { i18n } = useTranslation()

    return (
        <CardContainer>
            <PlaceHolder />
            {categories?.map((category, index) => {
                return (
                    <CardFull key={index} margin="7% 5% 0% 5%">
                        <Texture src={paperTexture}>
                            <CardWrapper>
                                <CardTitle>{i18n.language === 'en' ? category.english.name : category.name}</CardTitle>
                                {data?.map((current, index) => {
                                    const allergensList = current.allergens?.map((allergen) => {
                                        return allergen.short
                                    })
                                    if (allergensList[0] === 'na') allergensList.pop()
                                    if (current?.category?.name === category?.name)
                                        return (
                                            <CardItem key={index}>
                                                {allergensList.length > 0 && (
                                                    <CardItemText>{`${
                                                        i18n.language === 'en' ? current.english?.name : current.name
                                                    } (${allergensList})`}</CardItemText>
                                                )}
                                                {allergensList.length <= 0 && (
                                                    <CardItemText>{`${
                                                        i18n.language === 'en' ? current.english?.name : current.name
                                                    }`}</CardItemText>
                                                )}
                                                <TextWrapper>
                                                    <CardItemText>{`${current.price}.-`}</CardItemText>
                                                </TextWrapper>
                                            </CardItem>
                                        )
                                    return []
                                })}
                            </CardWrapper>
                        </Texture>
                    </CardFull>
                )
            })}
            <PlaceHolder />
        </CardContainer>
    )
}

export default MenuCardsFood
