import { getRestaurantForService } from 'restaurant'

import http from './http-common'

export const post = async (data: any) => {
    return await http.post(`${getRestaurantForService()}/weekly`, data).then((res) => {
        return res
    })
}

export const deleteItem = (data: { id: any }) => {
    return http.delete(`${getRestaurantForService()}/weekly/${data.id}`).then((res) => {
        return res
    })
}

export const update = async (data: any) => {
    return await http.put(`${getRestaurantForService()}/weekly/${data._id}`, data).then((res) => {
        return res
    })
}

export const getAll = async () => {
    return await http.get(`${getRestaurantForService()}/weekly`).then((res) => {
        return res
    })
}

export const getAllCategories = async () => {
    return await http.get(`${getRestaurantForService()}/categories/weekly/`).then((res) => {
        return res
    })
}
