import * as service from 'services/shop.service'
import { shopProps } from 'types'
import { createFormData } from 'utils'

export const getAllFunction = async (setState: React.Dispatch<React.SetStateAction<shopProps[] | undefined>>): Promise<void> => {
    try {
        const res = await service.getAll()
        setState(res.data)
    } catch (error) {
        throw error
    }
}

export const updateFunction = async (): Promise<void> => {
    try {
        await service.update({})
    } catch (error) {
        throw error
    }
}

export const postFunction = async (
    name: string,
    englishName: string,
    price: string,
    salePrice: string,
    onSale: string,
    imageFile: any
): Promise<void> => {
    try {
        const formData = createFormData({
            name,
            englishName,
            price,
            salePrice,
            onSale,
            image: imageFile,
        })

        await service.post(formData)
    } catch (error) {
        throw error
    }
}

export const deleteFunction = async (id: string, image: string) => {
    try {
        const res = await service.deleteItem({ id, image })
        return res
    } catch (error) {
        throw error
    }
}
