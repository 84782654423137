import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getRestaurantBackground } from 'restaurant'
import MenuCardsDrinks from 'site/components/Cards/MenuCardsDrinks'
import { Frame } from 'site/components/Frame'
import { BackgroundContainer } from 'style'
import { categoryProps, drinksProps } from 'types'

import { getAllDrinks } from './functions'

const Drinks = () => {
    const { pathname } = useLocation()
    const [drinks, setDrinks] = useState<drinksProps[]>()
    const [categoriesList, setCategoriesList] = useState<categoryProps[]>()

    useEffect(() => {
        getAllDrinks(setDrinks, setCategoriesList)
    }, [])

    return (
        <BackgroundContainer src={getRestaurantBackground(pathname)}>
            <Frame>
                <MenuCardsDrinks data={drinks} categories={categoriesList}></MenuCardsDrinks>
            </Frame>
        </BackgroundContainer>
    )
}

export default Drinks
