import { Link } from '@chakra-ui/react'
import React from 'react'

import BaseSVG from './fecskeHover/BaseSVG'
import ContactSVG from './fecskeHover/ContactSVG'
import DrinksSVG from './fecskeHover/DrinksSVG'
import MenuSVG from './fecskeHover/MenuSVG'
import TextSVG from './fecskeHover/TextSVG'
import WeeklySVG from './fecskeHover/WeeklySVG'

function FecskeHover() {
    return (
        <>
            <svg id="fecskerajz" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1240 960" style={{ maxWidth: '100%' }}>
                <defs>
                    <style>{'.cls-1{fill:#fff}.cls-2{fill:#ffad1d}.cls-3{fill:#f0ddbd}'}</style>
                </defs>
                <BaseSVG></BaseSVG>
                <Link href="/fecske/etlap">
                    <MenuSVG></MenuSVG>
                </Link>
                <Link href="/fecske/itallap">
                    <DrinksSVG></DrinksSVG>
                </Link>
                <Link href="/fecske/hetimenu">
                    <WeeklySVG></WeeklySVG>
                </Link>
                <Link href="/kapcsolat">
                    <ContactSVG></ContactSVG>
                </Link>

                <path id="blackbox" d="M.3 0h1242.6v157H.3z" />
                <TextSVG></TextSVG>
            </svg>
        </>
    )
}

export default FecskeHover
