import {
    Badge,
    Button,
    Container,
    HStack,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react'
import { DeleteDialog } from 'admin/components/customModal/deleteDialog'
import { CustomModalEdit } from 'admin/components/customModal/edit'
import { CustomModalNew } from 'admin/components/customModal/new'
import React, { useEffect, useReducer, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { TdStyle } from 'style'
import { announcementProps } from 'types'

import AnnouncementForm from './announcementForm'

interface Props {
    title: string
    getAllFunc: (setAnnouncement: React.Dispatch<React.SetStateAction<announcementProps[] | undefined>>) => Promise<void>
    postFunc: (description: string, englishDescription: string) => void
    updateFunc: (id: string, description: string, englishDescription: string, active: boolean) => void
    deleteFunc: (id: string) => any
}

const AnnouncementTable = ({ getAllFunc, postFunc, updateFunc, deleteFunc, title }: Props) => {
    const [announcement, setAnnouncement] = useState<announcementProps[]>()

    const [refresh, forceUpdate] = useReducer((x) => x + 1, 0)
    const [data, setData] = useState<announcementProps>()

    const headers = ['Leírás', 'Angol leírás']
    const { isOpen, onOpen, onClose } = useDisclosure()

    const postHandler = async (description: string, englishDescription: string) => {
        try {
            await postFunc(description, englishDescription)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const updateHandler = async (id: string, description: string, englishDescription: string, active: boolean) => {
        try {
            await updateFunc(id, description, englishDescription, active)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    const deleteHandler = async (delete_id: string) => {
        try {
            await deleteFunc(delete_id)
            forceUpdate()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getAllFunc(setAnnouncement)
    }, [getAllFunc, refresh])

    return (
        <>
            <Container maxW="100%" bg="white" rounded={'md'}>
                <HStack paddingTop={6} paddingBottom={6} justifyContent={'space-between'}>
                    <Text fontSize="xl" fontWeight={'bold'}>
                        {title}
                    </Text>
                    <CustomModalNew modalName={`${title} hozzáadása`}>
                        <AnnouncementForm postForm={postHandler}></AnnouncementForm>
                    </CustomModalNew>
                    <CustomModalEdit modalName={`${title} szerkesztése`} onClose={onClose} isOpen={isOpen}>
                        <AnnouncementForm data={data} updateForm={updateHandler}></AnnouncementForm>
                    </CustomModalEdit>
                </HStack>
                <TableContainer>
                    <Table variant="striped" colorScheme="teal">
                        <Thead>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                                <Th>{<Badge colorScheme="green">Aktiválás</Badge>}</Th>
                                <Th>{<Badge colorScheme="blue">Módosítás</Badge>}</Th>
                                <Th>{<Badge colorScheme="red">Törlés</Badge>}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {announcement &&
                                announcement.map((item, index) => {
                                    return (
                                        <Tr key={index}>
                                            <Td style={TdStyle}>{item.description}</Td>
                                            <Td style={TdStyle}>{item.english.description}</Td>
                                            <Td>
                                                <Button
                                                    height={'6'}
                                                    background={item.active ? 'red.200' : 'green.200'}
                                                    _hover={{
                                                        background: item.active ? 'red.400' : 'green.400',
                                                    }}
                                                    onClick={() => {
                                                        updateHandler(item._id, item.description, item.english.description, !item.active)
                                                    }}
                                                >
                                                    {item.active ? 'Deaktiválás' : 'Aktiválás'}
                                                </Button>
                                            </Td>
                                            <Td>
                                                <Icon
                                                    as={FiEdit2}
                                                    onClick={() => {
                                                        onOpen()
                                                        setData(item)
                                                    }}
                                                ></Icon>
                                            </Td>
                                            <Td>
                                                <DeleteDialog
                                                    title={'Kategória törlése'}
                                                    question={`Biztosan törölni szeretnéd a(z) elemet?`}
                                                    deleteItem={deleteHandler}
                                                    delete_id={item._id}
                                                ></DeleteDialog>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                {headers.map((header, index) => {
                                    return <Th key={index}>{header}</Th>
                                })}
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default AnnouncementTable
