import styled from 'styled-components'

export const Border = styled.div`
    background: radial-gradient(
        circle,
        rgba(232, 221, 181, 1) 0%,
        rgba(224, 199, 132, 1) 33%,
        rgba(197, 179, 125, 1) 83%,
        rgba(194, 170, 106, 1) 91%,
        rgba(161, 129, 68, 1) 100%
    );
    border-width: 3.8rem;
    border-image: url(/fecske_border.png) 34% 33.5% repeat;
    border-style: solid;
    width: 60%;
    max-width: 1280px;
    height: min-content;
    margin: auto;
    z-index: 10;
    box-shadow: inset 0 0 20px 5px #000000;

    @media (max-width: 1440px) {
        width: 70%;
    }

    @media (max-width: 1280px) {
        width: 90%;
    }

    @media (min-width: 1024px) and (max-width: 1200px) {
    }

    @media screen and (max-width: 1023px) {
    }

    @media (min-width: 768px) and (max-width: 1023px) {
    }

    @media (max-width: 767px) {
        width: 95%;
        border-width: 2rem;
    }
`

export const Texture = styled.div<{ src?: string }>`
    width: 100%;
    height: calc(100% - 6.25rem);
    position: relative;
    &:after {
        content: '';
        background: url(${({ src }) => src});
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
    }
`
export const SimpleBackground = styled.div`
    width: 100%;
    height: calc(100% - 100px);
    position: relative;
    background: linear-gradient(
        210deg,
        rgba(56, 33, 12, 1) 0%,
        rgba(52, 30, 10, 1) 25%,
        rgba(45, 25, 8, 1) 50%,
        rgba(36, 19, 5, 1) 75%,
        rgba(30, 15, 3, 1) 100%
    );
    box-shadow: inset 0 0 30px #000;
`

export const Banner = styled.div`
    background: linear-gradient(180deg, rgba(38, 22, 7, 1) 0%, rgba(38, 22, 7, 1) 25%, rgba(52, 30, 10, 1) 100%);
    width: 100%;
    height: 6.25rem;
    box-shadow: inset 0px 6px 15px -5px #000000, inset 7px 0 15px -30px #000000, inset -7px 0 15px -30px rgba(0, 0, 0, 0.4);
`

export const BannerCircle = styled.div`
    position: absolute;
    margin-top: 0.625rem;
    background: linear-gradient(180deg, rgba(38, 22, 7, 1) 0%, rgba(52, 30, 10, 1) 50%);
    border-radius: 50%;
    width: 11.25rem;
    height: 11.25rem;
    z-index: 9;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`
