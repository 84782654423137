import { Link } from '@chakra-ui/react'
import {
    MobileButton,
    MobileContainer,
    MobileContainerWrapper,
    MobileTitle,
    MobileWrapperHorizontal,
    MobileWrapperVertical,
} from 'pages/LandingPage/LandingPage.styled'

export const MobileRestaurants = () => {
    return (
        <MobileContainer>
            <MobileContainerWrapper style={{ marginBottom: '1rem' }}>
                <MobileWrapperVertical>
                    <MobileTitle style={{ alignSelf: 'flex-start', marginLeft: '1.5rem' }}>Fecske</MobileTitle>
                    <MobileWrapperHorizontal>
                        <Link key={'fecske_etlap'} href={'/fecske/etlap'} style={{ textDecoration: 'none' }}>
                            <MobileButton>Étlap</MobileButton>
                        </Link>
                        <Link key={'fecske_hetimenu'} href={'/fecske/hetimenu'} style={{ textDecoration: 'none' }}>
                            <MobileButton>Heti menü</MobileButton>
                        </Link>
                        <Link key={'fecske_itallap'} href={'/fecske/itallap'} style={{ textDecoration: 'none' }}>
                            <MobileButton>Itallap</MobileButton>
                        </Link>
                    </MobileWrapperHorizontal>
                </MobileWrapperVertical>
            </MobileContainerWrapper>
            <MobileContainerWrapper>
                <MobileWrapperVertical>
                    <MobileTitle style={{ alignSelf: 'flex-end', marginRight: '1.5rem' }}>Kis Fecske</MobileTitle>
                    <MobileWrapperHorizontal>
                        <Link key={'kisfecske_etlap'} href={'/kisfecske/etlap'} style={{ textDecoration: 'none' }}>
                            <MobileButton>Étlap</MobileButton>
                        </Link>
                        <Link key={'kisfecske_hetimenu'} href={'/kisfecske/hetimenu'} style={{ textDecoration: 'none' }}>
                            <MobileButton>Heti menü</MobileButton>
                        </Link>
                        <Link key={'kisfecske_itallap'} href={'/kisfecske/itallap'} style={{ textDecoration: 'none' }}>
                            <MobileButton>Itallap</MobileButton>
                        </Link>
                    </MobileWrapperHorizontal>
                </MobileWrapperVertical>
            </MobileContainerWrapper>
        </MobileContainer>
    )
}
