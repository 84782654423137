import { HStack, VStack } from '@chakra-ui/react'
import { getAllFunction } from 'admin/components/tables/news/functions'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { newsProps } from 'types'

import { backendUrl } from '../../../utils'
import { CaptionContainerDesktop, CaptionContainerMobile, CarouselH1, CarouselP, Image } from './Carousel.style'

const CarouselComponent = () => {
    const [news, setNews] = useState<newsProps[]>()

    useEffect(() => {
        getAllFunction(setNews)
    }, [])

    const { i18n } = useTranslation()

    return (
        <Carousel fade={true} pause="hover" touch={true}>
            <Carousel.Item
                interval={5000}
                style={{
                    height: '25rem',
                    background: '#200f01',
                }}
            >
                <Carousel.Caption
                    style={{
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        right: 'unset',
                        bottom: 'unset',
                    }}
                >
                    <HStack justifyContent={'space-evenly'}>
                        <VStack>
                            <CarouselH1>
                                {i18n.language === 'en'
                                    ? 'Welcome to the website of the Fecske and Kis Fecske restaurants!'
                                    : 'Üdvözlünk a Fecske és Kis Fecske oldalán!'}
                            </CarouselH1>
                        </VStack>
                    </HStack>
                </Carousel.Caption>
            </Carousel.Item>
            {news &&
                news.map((item, index) => {
                    return (
                        <Carousel.Item
                            key={index}
                            interval={5000}
                            style={{
                                height: '25rem',
                                background:
                                    'linear-gradient(45deg, rgba(51, 29, 10, 1) 0%, rgba(59, 36, 15, 1) 25%, rgba(79, 51, 27, 1) 65%, rgba(85, 56, 30, 1) 100%)',
                            }}
                        >
                            <CaptionContainerDesktop>
                                <Carousel.Caption
                                    style={{
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        right: 'unset',
                                        bottom: 'unset',
                                        width: '60%',
                                        margin: 'auto',
                                    }}
                                >
                                    <HStack justifyContent={'space-between'}>
                                        <VStack>
                                            <CarouselH1>{item.name}</CarouselH1>
                                            <CarouselP style={{ width: '80%' }}>{item.abstract}</CarouselP>
                                        </VStack>
                                        <Image src={`${backendUrl}${item.image}`}></Image>
                                    </HStack>
                                </Carousel.Caption>
                            </CaptionContainerDesktop>
                            <CaptionContainerMobile>
                                <Carousel.Caption
                                    style={{
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        right: 'unset',
                                        bottom: 'unset',
                                        width: '100%',
                                        margin: 'auto',
                                    }}
                                >
                                    <HStack justifyContent={'space-between'}>
                                        <VStack>
                                            <CarouselH1>{item.name}</CarouselH1>
                                            <CarouselP style={{ width: '80%' }}>{item.abstract}</CarouselP>
                                        </VStack>
                                    </HStack>
                                </Carousel.Caption>
                            </CaptionContainerMobile>
                        </Carousel.Item>
                    )
                })}
        </Carousel>
    )
}

export default CarouselComponent
